import Moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as IoIcons from "react-icons/io";
import { FaCog } from "react-icons/fa";
import { Context } from "../../../../Context/Contexts";
import ErrorPopUp from "../../../Errors/ErrorPopUp";
import { GetStyleToAdjustHeight_Alt } from "../../../Helpers/GetStyleToAdjustHeight";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import NewYearPlannedActivity from "../../AddYearPlannedActivity/NewYearPlannedActivity";
import NewUserYearPlannedActivity from "../../NewUserYearPlannedActivity/NewUserYearPlannedActivity";
import ActivityOptions from "../ActivityOptions/ActivityOptions";
import { GetYearActivitiesByYear } from "../YearActivities/GetYearActivities";
import "./GetYearActivitiesTable.css";
import YearSelector from "../YearSelector/YearSelector";
import { Typography } from "@mui/material";

export function GetYearActivitiesTable_Alt({
	selectedYear,
	selectedChild,
	setSelectedYear,
}) {
	var loadCount = 0;
	const lang = sessionStorage.getItem("_lang");
	const [showActivityOptions, setShowActivityOptions] = useState(false);
	const [activityForOptions, setActivityForOptions] = useState("");
	const [newUserActivityIsOpen, setNewUserActivityIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const { updateHeight, setUpdateHeight } = useContext(Context);
	const { refreshYearPlanner, setRefreshYearPlanner } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);
	const [showYearSelector, setShowYearSelector] = useState(false);

	const [filterby, setFilterby] = useState("");
	const [listForFiltering, setListForFiltering] = useState([]);

	const { isloading, setIsloading } = useContext(Context);
	const { setShowIsloading } = useContext(Context);

	const [yearActivitiesList, setYearActivitiesList] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const [activityToPlan, setActivityToPlan] = useState("");

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(1000);
	const [totalPages, SetTotalPages] = useState(0);

	const inputRef = useRef(null);

	const OpenOptions = (activity) => {
		setActivityForOptions(activity);
		setShowActivityOptions(true);
	};

	const PlanclickHandler = (row) => {
		if (!YearAndStudentSelected()) {
			return <></>;
		}
		setActivityToPlan(row.activity);
		setIsOpen(true);
	};

	const PlanComponent = ({ row }) => {
		return (
			<div
				className="year-activity-plan-btn hover-brighten"
				onClick={() => {
					PlanclickHandler(row);
				}}
			>
				<Typography variant="tableRow">{GetPrompts("Planner Plan")}</Typography>
			</div>
		);
	};

	const GetActivities = () => {
		setShowIsloading(true);
		GetYearActivitiesByYear({
			currentPage,
			itemsPerPage,
			selectedYear: selectedYear,
			selectedChild: selectedChild.planChildId,
			thirdPartySelectedUserId: thirdPartySelectedUserId,
		}).then(function (response) {
			setShowIsloading(false);

			SetTotalPages(response.pages);

			setYearActivitiesList(response.result);
			setListForFiltering(response.result);
			setUpdateHeight(true);
			FocusOnInput();
		});
	};

	const NewYearActivityClickHandler = () => {
		setNewUserActivityIsOpen(true);
	};

	const ApplyFilter = () => {
		var filteredJsonArray = listForFiltering.filter((item) => {
			return (
				item.activity.description
					.toLowerCase()
					.includes(filterby.toLowerCase()) ||
				item.activity.descriptionHint
					.toLowerCase()
					.includes(filterby.toLowerCase())
			);
		});
		setYearActivitiesList(filteredJsonArray);
	};

	const FocusOnInput = () => {
		inputRef.current.focus();
	};
	const Filter = ({ value }) => {
		const handleChange = (event) => {
			setFilterby(event.target.value);
		};

		return (
			<div className="year-activities-table-filter-container">
				<Typography variant="body1">
					<input
						onClick={() => {
							FocusOnInput();
						}}
						autoFocus
						ref={inputRef}
						className="year-activities-table-filter"
						type="text"
						onChange={handleChange}
						value={filterby}
						placeholder="Filtrar..."
					/>
				</Typography>
			</div>
		);
	};

	useEffect(() => {
		FocusOnInput();
	}, [yearActivitiesList]);
	useEffect(() => {
		ApplyFilter();
	}, [filterby]);

	useEffect(() => {
		GetActivities();
	}, [selectedYear, selectedChild]);

	useEffect(() => {
		if (refreshYearPlanner == true) {
			setRefreshYearPlanner(false);
			GetActivities();
		}
	}, [refreshYearPlanner]);

	function YearAndStudentSelected() {
		if (selectedChild == "Escoge un estudiante" || selectedChild == 0) {
			setErrorMessage(GetPrompts("Please select one student"));
			setErrorPopUpIsOpen(true);
			return false;
		}

		return true;
	}

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetActivities();
		loadCount = loadCount + 1;
	};

	const boxRef = useRef();
	const { STYLES } = GetStyleToAdjustHeight_Alt(boxRef);

	return (
		<>
			<div className="year-activities-header-container">
				<div className="year-activities-title base-title">
					<Typography variant="baseTitle">
						{GetPrompts("Plannable Subjects")}
					</Typography>
				</div>
				<div className="year-activities-filter table-row-item">
					{<Filter />}
				</div>
			</div>

			<div
				className="year-activities-table-container"
				ref={boxRef}
				style={STYLES}
			>
				<div>
					<table className="year-activities-table">
						<tr className="year-activities-table-headers table-row-header">
							<th>
								<span className="year-activities-table-headers-text">
									<Typography variant="tableHeader">
										{GetPrompts("Planner School Subject")}{" "}
									</Typography>
								</span>
								<span
									className="year-activities-table-add-activity"
									onClick={() => {
										setNewUserActivityIsOpen(true);
									}}
								>
									<IoIcons.IoMdAddCircle />
								</span>
							</th>
							<th>
								<span className="year-activities-table-headers-text">
									<Typography variant="tableHeader">
										{GetPrompts("Planner Last Plan")}
									</Typography>
								</span>
							</th>
							<th>
								{/* <span className="year-activities-table-headers-text">
									<Typography variant="tableHeader">
										{GetPrompts("Planner Plan")}
									</Typography>
								</span>{" "} */}
							</th>
						</tr>
						{yearActivitiesList.map((row, index) => {
							return (
								<tr key={`year-activities-table-activity-item-key-${index}`}>
									<td className="table-content">
										<div
											className="year-plan-activity-item table-row-item"
											onClick={() => OpenOptions(row)}
										>
											<Typography variant="tableRow">
												{`${row.activity[`description${lang}`]}`}
												{"  "}
												{row.hasNotes ? (
													<IoIcons.IoMdDocument className="icon-doc" />
												) : (
													""
												)}
											</Typography>
										</div>
									</td>

									<td>
										{row.lastPlanned !== null ? (
											<div className="year-plan-last-planned table-row-item">
												<Typography variant="tableRow">
													<span className="year-plan-check-mark">
														<IoIcons.IoMdCheckmark />
													</span>
													{Moment(
														new Date(
															Moment(row.lastPlanned).format(
																"M/D/YY hh:mm:ss A UTC"
															)
														).toString()
													).format("MMM-D/YY")}
												</Typography>
											</div>
										) : (
											""
										)}
									</td>
									<td>{PlanComponent({ row })}</td>
								</tr>
							);
						})}
					</table>
				</div>

				{showActivityOptions ? (
					<ActivityOptions
						activity={activityForOptions}
						dlgOpen={showActivityOptions}
						onclose={() => setShowActivityOptions(false)}
						planActivity={() => PlanclickHandler(activityForOptions)}
					/>
				) : (
					""
				)}
			</div>

			<NewYearPlannedActivity
				open={isOpen}
				plannedActivity={activityToPlan}
				onClose={() => setIsOpen(false)}
				GetPlannedActivities={() => {
					return;
				}}
				setUserId={0}
				selectedChild={selectedChild.planChildId}
				selectedChildDisplay={selectedChild.firstName}
				currentYearId={selectedChild.yearId}
			/>
			<NewUserYearPlannedActivity
				open={newUserActivityIsOpen}
				onClose={() => setNewUserActivityIsOpen(false)}
				yearId={selectedYear.yearId}
			/>

			<YearSelector
				isopen={showYearSelector}
				close={() => setShowYearSelector(false)}
				selectedYear={selectedYear}
				setSelectedYear={setSelectedYear}
			/>

			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
}
