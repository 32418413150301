import React from "react";

import "./ThirdPartyAdminModule.css";
import Sidebar from "../../Components/Sidebar/Sidebar";
import ThirdPartyAdminSidebar from "../../Components/Sidebar/ThirdPartyAdminSidebar";
import ThirdPartyAdminUsersList from "../../Components/ThirdPartyAdminModule/ThirdPartyAdminUsersList/ThirdPartyAdminUsersList";
import ThirdPartyUserEdition from "../../Components/ThirdPartyAdminModule/ThirdPartyUserEdition/ThirdPartyUserEdition";

export default function ThirdPartyAdminModule() {
	return (
		<>
			<Sidebar />
			<div className="third-party-admin-module-container">
				<div className="third-party-admin-module-sidebar">
					<ThirdPartyAdminSidebar />
				</div>
				<div className="third-party-admin-module-center">
					<ThirdPartyAdminUsersList />
				</div>
				<div className="third-party-admin-module-details-container">
					<ThirdPartyUserEdition />
				</div>
			</div>
		</>
	);
}
