export const MODAL_STYLES = {
	position: "fixed",
	width: "auto",
	height: "auto",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1000,
};

export const OVERLAY_STYLES = {
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(255, 255, 255, .7)",
	zIndex: 1000,
};
