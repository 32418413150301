import axios from "../../axios";
export const CreateNewChildForThirdPartyLinkedUser = async ({
	userId,
	firstName,
	lastName,
	identification,
	dateOfBirth,
	yearId,
}) => {
	try {
		const url = `Child/api/v1/CreateNewChildForThirdPartyLinkedUser`;

		const response = await axios.put(url, {
			UserId: userId,
			FirstName: firstName,
			LastName: lastName,
			Identification: identification,
			DateOfBirth: dateOfBirth,
			YearId: yearId,
		});
		return response.data;
	} catch (error) {}
};
