import customAxios from "../../axios";

export const DeletePlannedWeek = async ({ calendarWeek, childId }) => {
	try {
		const url = `Planner/api/v1/DeletePlannedWeek`;

		const response = await customAxios.post(url, {
			UserId: 0,
			CalendarWeek: calendarWeek,
			ChildId: childId,
		});
		return response.data;
	} catch (error) {}
};
