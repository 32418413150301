import customAxios from "../../../axios";

export const ApplyAdminPlanToThirdPartyLinkedUser = async ({
	userId,
	sourceChildId,
	targetChildId,
	startFromDate,
}) => {
	try {
		const url = `PlannerAdmin/api/v1/ApplyAdminPlanToThirdPartyLinkedUser`;

		const response = await customAxios.put(url, {
			userId: userId,
			SourcePlanChildId: sourceChildId,
			DestinationPlanChildId: targetChildId,
			StartFromDate: startFromDate,
		});
		return response.data;
	} catch (error) {}
};
