import { DndContext, closestCenter } from "@dnd-kit/core";
import {
	SortableContext,
	arrayMove,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import EditPlannedActivity from "../../EditPlannedActivity/EditPlannedActivity";
import PlannedGroupedActivity from "../../OldPlanner/PlannedGroupedActivity";
import { UpdatePlannedActivityOrder } from "../Actions/UpdatePlannedActivityOrder";
import SortableActivity from "./SortableActivity";
import { Context } from "../../../../Context/Contexts";

export default function DayOrderList(activities, GetActivities) {
	const { activitiesInEditionList, setActivitiesInEditionList } =
		useContext(Context);
	const { selectedChildGlobal } = useContext(Context);
	const [orderedActivities, setOrderedActivities] = useState([]);
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [plannedActivityInEdition, setPlannedActivityInEdition] = useState("");
	const [editGroupIsOpen, setEditGroupIsOpen] = useState(false);
	const [plannedGroupedActivitiesList, setPlannedGroupedActivitiesList] =
		useState([]);
	const [activeIndex, setActiveIndex] = useState(null);

	const [saveOrder, setSaveOrder] = useState(false);

	const ctrlKeyPressed = useRef(false);

	const clickOne = useRef(null);
	const clickTwo = useRef(null);

	const FeedOrderedList = () => {
		if (saveOrder == false) {
			return;
		}
		var position = 0;
		var orderedList = [];
		orderedActivities.map((orderedActicity) => {
			var array = {
				plannerId: orderedActicity.plannerId,
				position: position,
			};
			orderedList.push(array);
			position = position + 1;
		});

		var childId =
			selectedChildGlobal != undefined ? selectedChildGlobal.planChildId : 0;
		UpdatePlannedActivityOrder(orderedList, childId).then((response) => {
			GetActivities();
		});
		setSaveOrder(false);
	};

	const isSelectedForEdition = (id) => {
		return activitiesInEditionList.some((item) => item.plannerId === id);
	};
	//Move to separate file
	const AddToActivitiesInEditionList = (id) => {
		return new Promise((resolve) => {
			const activity = orderedActivities.find((item) => item.plannerId === id);
			if (!activity) {
				resolve();
				return;
			}

			setActivitiesInEditionList((prevList) => {
				const isPresent = prevList.some((item) => item.plannerId === id);
				if (isPresent) {
					return prevList.filter((item) => item.plannerId !== id);
				} else {
					return [...prevList, activity];
				}
			});
			resolve();
		});
	};

	const handleDragStart = (event) => {
		const { active } = event;
		clickOne.current = new Date().getTime();
		setActiveIndex(active.data.current.sortable.index);
	};

	const handleDragOver = (event) => {
		const { active, over } = event;
		if (over && active.id !== over.id) {
			const oldIndex = orderedActivities.findIndex(
				(item) => item.plannerId === active.id
			);
			const newIndex = orderedActivities.findIndex(
				(item) => item.plannerId === over.id
			);

			setOrderedActivities((items) => arrayMove(items, oldIndex, newIndex));
			setActiveIndex(newIndex);
		}
	};

	const handleDragEnd = async (event) => {
		clickTwo.current = new Date().getTime();

		const { active, over } = event;

		if (ctrlKeyPressed.current) {
			await AddToActivitiesInEditionList(active.id);
			return;
		}

		if (clickTwo.current - clickOne.current < 300) {
			var activity = orderedActivities.find(function (item, i) {
				if (item.plannerId === active.id) {
					clickHandler(item);
					return i;
				}
			});

			return;
		}

		setOrderedActivities((items) => {
			var activeIndex;
			var overIndex;

			items.find(function (item, i) {
				if (item.plannerId === active.id) {
					activeIndex = i;
				}
				if (item.plannerId === over.id) {
					overIndex = i;
				}
			});
			setSaveOrder(true);
			return arrayMove(items, activeIndex, overIndex);
		});
		setActiveIndex(null);
	};

	useEffect(() => {
		if (activities !== undefined) {
			setOrderedActivities(activities);
		}
	}, [activities]);

	useEffect(() => {
		if (orderedActivities !== undefined) {
			FeedOrderedList();
		}
	}, [orderedActivities]);

	useEffect(() => {
		const handleMouseDown = (event) => {
			ctrlKeyPressed.current = event.ctrlKey;
		};

		window.addEventListener("mousedown", handleMouseDown);
		return () => {
			window.removeEventListener("mousedown", handleMouseDown);
		};
	}, []);

	if (orderedActivities == undefined) {
		return;
	}

	const clickHandler = (activity) => {
		if (activity.grouped.length > 1) {
			setPlannedGroupedActivitiesList(activity.grouped);
			setEditGroupIsOpen(true);
		} else {
			setPlannedActivityInEdition(activity);
			setEditIsOpen(true);
		}
	};

	return (
		<>
			<DndContext
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
				onDragOver={handleDragOver}
				onDragStart={handleDragStart}
			>
				<SortableContext
					items={orderedActivities}
					strategy={verticalListSortingStrategy}
				>
					{orderedActivities.map((activity, index) => (
						<>
							<div key={`"sortable-activity-key"${activity.plannerId}`}>
								<SortableActivity
									key={activity.plannerId}
									id={activity.plannerId}
									activity={activity}
									isSelectedForEdition={isSelectedForEdition}
									addToActivitiesInEditionList={AddToActivitiesInEditionList}
									isDragging={activeIndex === index}
								/>
							</div>
						</>
					))}
				</SortableContext>
			</DndContext>

			<EditPlannedActivity
				open={editIsOpen}
				plannedActivity={plannedActivityInEdition}
				onClose={() => setEditIsOpen(false)}
				GetPlannedActivities={GetActivities}
				setUserId={() => {
					return;
				}}
			/>

			<PlannedGroupedActivity
				open={editGroupIsOpen}
				plannedActivitiesList={plannedGroupedActivitiesList}
				onClose={() => setEditGroupIsOpen(false)}
				GetPlannedActivities={GetActivities}
			/>
		</>
	);
}
