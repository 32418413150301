import customAxios from "../../../axios";

export async function GetThirdPartyLinkedUserById({ userId }) {
	const url = "User/api/v1/GetThirdPartyLinkedUserById";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: userId,
			},
		});
		return response.data;
	} catch (error) {}
}
