import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import "./MarkActivityAsPendingIcon.css";
import { Context } from "../../../../Context/Contexts";
import { MarkPlannedActivityAsPending } from "./MarkPlannedActivityAsPending";
import { MarkPlannedActivityAsNotPending } from "./MarkPlannedActivityAsNotPending";

export default function MarkActivityAsPendingIcon({ activity, show = false }) {
	const { setRefreshWeeklySchedule } = useContext(Context);
	const clickHandler = () => {
		if (activity.isPending === true) {
			MarkPlannedActivityAsNotPending({ plannerId: activity.plannerId }).then(
				(response) => {
					setRefreshWeeklySchedule(true);
				}
			);
		} else {
			MarkPlannedActivityAsPending({ plannerId: activity.plannerId }).then(
				(response) => {
					setRefreshWeeklySchedule(true);
				}
			);
		}
	};

	return (
		<>
			<div
				className="mark-activity-as-pendingI-icon-container"
				title={`${
					activity.isPending ? "Actividad pendiente" : "Marcar como pendiente"
				}`}
				onClick={clickHandler}
			>
				{activity.isPending === true ? (
					<div className={`mark-activity-as-pendingI-icon-pending`}>
						<IoIcons.IoMdFlag />
					</div>
				) : show === true ? (
					<div className={`mark-activity-as-pendingI-icon-none`}>
						<IoIcons.IoMdFlag />
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
}
