import customAxios from "../../../axios";

export const ApplyAutomatedTermPlanning = async ({
	childId,
	yearId,
	fromDate,
	numberOfWeeks,
	requestList,
	thirdPartySelectedUserId = 0,
}) => {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/ApplyAutomatedTermPlanning"
				: "Planner/api/v1/ApplyAutomatedTermPlanningForThirdPartyLinkedUser";

		const response = await customAxios.post(url, {
			UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			PlanChildId: childId,
			YearId: yearId,
			FromDate: fromDate,
			NumberOfWeeks: numberOfWeeks,
			AutomatedActivityRequestList: requestList,
		});
		return response.data;
	} catch (error) {}
};
