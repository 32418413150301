import customAxios from "../../../../axios";

export const EditMultiplePlannedActivitiesComment = async ({
	plannedActivitiesIdList,
	comments,
	formattedComments,
	thirdPartySelectedUserId = 0,
}) => {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/EditMultiplePlannedActivitiesComment"
				: "Planner/api/v1/EditMultiplePlannedActivitiesCommentForThirdPartyLinkedUser";

		const response = await customAxios.post(url, {
			UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			PlannedActivitiesIdList: plannedActivitiesIdList,
			Comments: comments,
			FormattedComments: formattedComments,
		});
	} catch (error) {}
};
