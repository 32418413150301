import React, { useContext, useState } from "react";
import ModalComponent from "../../ModalComponent/ModalComponent";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./ThirdPartySelectedUserOptions_ModalStyle";
import { Context } from "../../../Context/Contexts";
import "./ThirdPartySelectedUserOptions.css";
import { AddThirdPartyLinkedUserMembershipToCart } from "../../../Pages/Membership/AddThirdPartyLinkedUserMembershipToCart";
import * as IoIcons from "react-icons/io";
import InfoPopup from "../../InfoPopup/InfoPopup";

export default function ThirdPartySelectedUserOptions({ isOpen, close }) {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { setRefreshCartItemCounter } = useContext(Context);
	const { thirdPartySelectedUserId, setThirdPartySelectedUserId } =
		useContext(Context);

	const [infoPopUpIsOpen, setInfoPopUpIsOpen] = useState(false);
	const [infoMessage, setInfoMessage] = useState("");

	const CloseClickHandler = () => {
		close();
	};

	const AddMembershipClickHandler = () => {
		setShowIsloading(true);
		AddThirdPartyLinkedUserMembershipToCart({
			userId: thirdPartySelectedUserId,
		}).then((response) => {
			setShowIsloading(false);
			console.log(response);
			if (response.errors != null) {
				setInfoMessage("Verifica si este usuario ya tiene una membresía");
				setInfoPopUpIsOpen(true);
				return;
			}
			setRefreshCartItemCounter(true);
			setShowDonecomponent(true);
		});
	};

	if (!isOpen) return;

	return (
		<>
			<ModalComponent
				isOpen={isOpen}
				onClose={CloseClickHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
				showBackgroundImage={false}
			>
				<div className="third-party-selected-user-options-container">
					<div
						className="third-party-selected-user-options-close-btn"
						onClick={CloseClickHandler}
					>
						X
					</div>
					<div className="third-party-selected-user-options-body">
						<div
							className="do-not-print hover-brighten base-button-text move-week-btn"
							onClick={() => {
								AddMembershipClickHandler();
							}}
							title="Añadir una membresía para este usuario"
						>
							<>
								<IoIcons.IoMdPerson /> Añadir membresía
							</>
						</div>
					</div>
					<div className="third-party-selected-user-options-body">
						<div
							className="do-not-print hover-brighten base-button-text move-week-btn"
							onClick={() => {
								console.log("Not implemented yet");
							}}
							title="Add membership"
						>
							<>
								<IoIcons.IoMdKey /> Reset Pwd
							</>
						</div>
					</div>
				</div>
			</ModalComponent>

			<InfoPopup
				icon={<IoIcons.IoMdAlert />}
				open={infoPopUpIsOpen}
				onClose={() => {
					setInfoPopUpIsOpen(false);
				}}
				errorMessage={infoMessage}
			/>
		</>
	);
}
