import "./ChildCard_Edit.css";

import "react-datepicker/dist/react-datepicker.css";
import ChildEdition from "./ChildEdition";

export default function ChildCard_Edit({
	open,
	child,
	onClose,
	getChildren,
	setUserId,
	isThirdPartyAdmin = false,
}) {
	return (
		<ChildEdition
			open={open}
			onClose={onClose}
			child={child}
			getChildren={getChildren}
			isThirdPartyAdmin={isThirdPartyAdmin}
		/>
	);
}
