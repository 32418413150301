import Moment from "moment";
import axios from "../../../axios";
export const DeletePlannedActivityByDateRange = async ({
	plannedActivity,
	deleteFromDate,
	deleteToDate,
	thirdPartySelectedUserId = 0,
}) => {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/DeletePlannedActivityByDateRange"
				: "Planner/api/v1/DeleteThirdPartyLinkedUserPlannedActivityByDateRange";

		const response = await axios.post(url, {
			UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			PlannerId: plannedActivity.plannerId,
			DeleteFromDate: Moment(deleteFromDate).format("YYYY-MM-DDT00:00:00"),
			DeleteToDate: Moment(deleteToDate).format("YYYY-MM-DDT11:59:00"),
		});
	} catch (error) {}
};
