import Moment from "moment";
import * as IoIcons from "react-icons/io";
import React, { useContext, useEffect, useState } from "react";
import EditPlannedActivity from "../../Components/Planner/EditPlannedActivity/EditPlannedActivity";

import DatePickerPopUp from "../../Components/DatePicker/DatePickerPopUp";
import PlannedGroupedActivity from "../../Components/Planner/OldPlanner/PlannedGroupedActivity";
import { GetWeeklyScheduleList } from "../../Components/Planner/WeeklySchedule/Actions/GetWeeklyScheduleList";
import { WeeklyScheduleDatatable } from "../../Components/Planner/WeeklySchedule/Datatable/WeeklyScheduleDatatable";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./WeeklySchedule.css";
import { GetWeekNumber } from "../../Components/Settings/Calendar/CrudPeriod/GetWeekNumber";
import { GetPeriodForOneWeek } from "../../Components/Settings/Calendar/CrudPeriod/GetPeriodForOneWeek";
import { CalendarPrintHeader } from "./WeeklySchedulePrintHeader";
import { WeeklyScheduleControls } from "./WeeklyScheduleControls";
import EditPlannedActivityMultiple from "../../Components/Planner/EditPlannedActivity/Multpiple/EditPlannedActivityMultiple";
import { GetWeekInfo } from "../../Components/Settings/Calendar/CrudPeriod/GetWeekInfo";

export default function WeeklySchedule({ handlePrint }) {
	var loadCount = 0;
	const lang = sessionStorage.getItem("_lang");
	const { selectedChildGlobal } = useContext(Context);
	const [userId, setUserId] = useState("");
	const { setShowFooter } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);
	const { refreshWeeklySchedule, setRefreshWeeklySchedule } =
		useContext(Context);
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [editGroupIsOpen, setEditGroupIsOpen] = useState(false);

	const [plannedActivityInEdition, setPlannedActivityInEdition] = useState("");
	const [plannedGroupedActivitiesList, setPlannedGroupedActivitiesList] =
		useState([]);

	const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);
	const [showDatePickerPopUp, setShowDatePickerPopUp] = useState(false);
	const [weekNumber, setWeekNumber] = useState(0);
	const [skipWeekTitle, setSkipWeekTitle] = useState("");
	const [periodNumber, setPeriodNumber] = useState(0);
	const [periodTitle, setPeriodTitle] = useState("Term");
	const [showEditPlannedActivityMultiple, setShowEditPlannedActivityMultiple] =
		useState(false);
	const [weekYear, setWeekYear] = useState(0);

	useEffect(() => {
		setShowFooter(false);
		setShowNavbarItems(false);
		setShowSidebarToggle(true);
	});

	const [weeklyScheduleList, setWeeklyScheduleList] = useState([]);

	const FeedWeeklySchedule = () => {
		if (weeklyScheduleDate == null) {
			setWeeklyScheduleDate(new Date());
		}

		var childId =
			selectedChildGlobal != undefined ? selectedChildGlobal.planChildId : 0;

		GetWeeklyScheduleList({
			weeklyScheduleDate,
			childId,
			thirdPartySelectedUserId,
		}).then(function (response) {
			feedWeekYear(response.weekYear);
			setWeeklyScheduleList(response.weeklyScheduleDtoList);
		});
	};

	const EditPlannedActivityClickHandler = (activity) => {
		setPlannedActivityInEdition(activity);

		if (activity.grouped.length > 1) {
			setPlannedGroupedActivitiesList(activity.grouped);
			setEditGroupIsOpen(true);
		} else {
			setEditIsOpen(true);
		}
	};

	const WeeklyScheduleDateEditionHandler = (e) => {
		setWeeklyScheduleDatePicker(new Date(e));
		setWeeklyScheduleDate(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};
	const [weeklyScheduleDatePicker, setWeeklyScheduleDatePicker] = useState(
		new Date()
	);

	const [weeklyScheduleDate, setWeeklyScheduleDate] = useState(new Date());

	const GetWeek = () => {
		GetWeekInfo({
			requestedDate: Moment(weeklyScheduleDate).format("MM-DD-YYYY"),
			thirdPartySelectedUserId,
		}).then((response) => {
			setWeekNumber(response.weekNumber);
			setSkipWeekTitle(response.weekTile);
		});
	};
	const GetPeriod = () => {
		GetPeriodForOneWeek({
			requestedDate: Moment(weeklyScheduleDate).format("MM-DD-YYYY"),
			thirdPartySelectedUserId,
		}).then((response) => {
			setPeriodNumber(response.result.periodNumber);
			setPeriodTitle(response.result.planCalendarPeriod.periodTitle);
		});
	};
	const feedWeekYear = (year) => {
		setWeekYear(year);
	};
	useEffect(() => {
		GetWeek();
		GetPeriod();
	}, [weeklyScheduleDate]);

	useEffect(() => {
		FeedWeeklySchedule();
	}, [weeklyScheduleDate]);

	useEffect(() => {
		FeedWeeklySchedule();
	}, [selectedChildGlobal]);

	useEffect(() => {
		if (refreshWeeklySchedule == true) {
			setRefreshWeeklySchedule(false);
			FeedWeeklySchedule();
		}
	}, [refreshWeeklySchedule]);

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetWeek();
		GetPeriod();
		loadCount = loadCount + 1;
	};

	return (
		<>
			<Sidebar />
			<div className="container weekly-schedule-page-container">
				<CalendarPrintHeader
					periodTitle={periodTitle}
					periodNumber={periodNumber}
					weekNumber={weekNumber}
					skipWeekTitle={skipWeekTitle}
				/>
				<WeeklyScheduleControls
					WeeklyScheduleDateEditionHandler={WeeklyScheduleDateEditionHandler}
					weeklyScheduleDate={weeklyScheduleDate}
					setShowEditPlannedActivityMultiple={
						setShowEditPlannedActivityMultiple
					}
					handlePrint={handlePrint}
					setShowDatePickerPopUp={setShowDatePickerPopUp}
					weeklyScheduleDatePicker={weeklyScheduleDatePicker}
					weekNumber={weekNumber}
					weekYear={weekYear}
				/>

				<div className="skip-week-tag-weekly-schedule do-not-print">
					{weekNumber === 0 ? (
						skipWeekTitle !== undefined ? (
							skipWeekTitle
						) : (
							"Semana de receso"
						)
					) : (
						<></>
					)}
				</div>

				<div className="weelkly-scheduler-table">
					{WeeklyScheduleDatatable({
						weeklyScheduleList,
						setPlannedActivityInEdition,
						setIsOpen: EditPlannedActivityClickHandler,
						currentDate: weeklyScheduleDate,
						getPlannedActivities: FeedWeeklySchedule,
					})}
				</div>
			</div>

			<EditPlannedActivityMultiple
				open={showEditPlannedActivityMultiple}
				onClose={() => setShowEditPlannedActivityMultiple(false)}
			/>

			<EditPlannedActivity
				open={editIsOpen}
				plannedActivity={plannedActivityInEdition}
				onClose={() => setEditIsOpen(false)}
				setUserId={setUserId}
			/>

			<PlannedGroupedActivity
				open={editGroupIsOpen}
				plannedActivitiesList={plannedGroupedActivitiesList}
				onClose={() => setEditGroupIsOpen(false)}
				GetPlannedActivities={FeedWeeklySchedule}
			/>

			<DatePickerPopUp
				open={showDatePickerPopUp}
				close={() => {
					setShowDatePickerPopUp(false);
				}}
				defaultValue={weeklyScheduleDatePicker}
				onChange={WeeklyScheduleDateEditionHandler}
			/>
		</>
	);
}
