import customAxios from "../../../axios";

export async function GetThirdPartyUserChildren() {
	const url = "PlannerAdmin/api/v1/GetThirdPartyUserChildren";
	try {
		const response = await customAxios.get(url, {
			params: {},
		});
		return response.data;
	} catch (error) {}
}
