import customAxios from "../../axios";

export const DuplicateActivity = async ({
	destinationCalendarWeek,
	plannedActivityId,
	thirdPartySelectedUserId = 0,
}) => {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/DuplicateActivity"
				: "Planner/api/v1/DuplicateActivityForThirdPartyLinkedUser";

		const response = await customAxios.post(url, {
			UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			PlannerId: plannedActivityId,
			DestinationDate: destinationCalendarWeek,
		});
		return response.data;
	} catch (error) {}
};
