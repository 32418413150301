import React, { useContext, useEffect, useState } from "react";
import { MODAL_STYLES, OVERLAY_STYLES } from "./AutomatedPlanning_ModalStyle";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { GetActivitiesForAutomatedPlanning } from "./GetActivitiesForAutomatedPlanning";
import DatePickerPopUp from "../../DatePicker/DatePickerPopUp";
import { ApplyAutomatedTermPlanning } from "./ApplyAutomatedTermPlanning";
import Moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./AutomatedPlanning.css";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import { Typography } from "@mui/material";
import YesNoConfirm from "../../ModalConfirm/YesNoConfirm";
import { Context } from "../../../Context/Contexts";
import ModalComponent from "../../ModalComponent/ModalComponent";
import { MEMBERSHIP_UPGRADE_NEEDED } from "../../Constants/Constants";
import { WaitForTransactionToFinish } from "../../Transactions/WaitForTransaction";

export default function AutomatedPlanning() {
	const lang = sessionStorage.getItem("_lang");
	const { selectedChildGlobal } = useContext(Context);
	const { setShowMembershipNeedUpgrade } = useContext(Context);
	const { setRefreshWeeklySchedule } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);
	const { showAutomatedPlanning, setShowAutomatedPlanning } =
		useContext(Context);
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent, setShowIsloadingMessage } = useContext(Context);

	const [activities, setActivities] = useState([]);
	const [dateFrom, setDateDateFrom] = useState(new Date());
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showFromDateDateTimePicker, setShowFromDateDateTimePicker] =
		useState(false);
	const [numberOfWeeks, setNumberOfWeeks] = useState(1);
	const closeHandler = () => {
		setShowAutomatedPlanning(false);
	};

	let childId =
		selectedChildGlobal != undefined ? selectedChildGlobal.planChildId : 0;
	let yearId =
		selectedChildGlobal != undefined ? selectedChildGlobal.yearId : 0;

	const getActivities = async () => {
		setShowIsloading(true);
		await GetActivitiesForAutomatedPlanning({ yearId: yearId }).then(
			(response) => {
				setShowIsloading(false);
				setActivities(response);
			}
		);
	};

	useEffect(() => {
		if (showAutomatedPlanning === true) {
			getActivities();
		}
	}, [showAutomatedPlanning]);

	const handleCheckboxChange = (activityId, day) => {
		setActivities((prevActivities) =>
			prevActivities.map((activity) =>
				activity.activityId === activityId
					? { ...activity, [day]: !activity[day] }
					: activity
			)
		);
	};
	const handleSaveChanges = async () => {
		setShowIsloading(true);
		setShowIsloadingMessage(
			"Por favor espera, esta operacion puede tardar un poco..."
		);
		await ApplyAutomatedTermPlanning({
			childId: childId,
			yearId: yearId,
			fromDate: dateFrom,
			numberOfWeeks: numberOfWeeks,
			requestList: activities,
			thirdPartySelectedUserId: thirdPartySelectedUserId,
		}).then(async (response) => {
			if (response.errors === MEMBERSHIP_UPGRADE_NEEDED) {
				setShowMembershipNeedUpgrade(true);
				setShowIsloading(false);
				setShowConfirmation(false);
				closeHandler();
				return;
			}

			const transactionId = response.transactionId;
			await WaitForTransactionToFinish({
				transactionId: transactionId,
				retryDelay: 5000,
				retries: 12,
			}).then((response) => {
				if (response.success) {
					triggerRefreshFunction();
				} else {
					console.log("Not done yet, we'll continue in the background...");
					triggerRefreshFunction();
				}
			});
		});
	};
	const triggerRefreshFunction = () => {
		setShowDonecomponent(true);
		setShowIsloading(false);
		setShowConfirmation(false);
		setRefreshWeeklySchedule(true);
		closeHandler();
	};

	const clearAllCheckboxes = () => {
		setActivities((prevActivities) =>
			prevActivities.map((activity) => ({
				...activity,
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false,
			}))
		);
	};

	if (!showAutomatedPlanning) return null;
	return (
		<>
			<ModalComponent
				isOpen={showAutomatedPlanning}
				onClose={closeHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
			>
				<PerfectScrollbar className="automated-planning-container">
					<div className="automated-planning-close-btn" onClick={closeHandler}>
						X
					</div>

					{selectedChildGlobal !== undefined ? (
						<div className="automated-planning-kid-name">
							<Typography variant="tableRow">
								{selectedChildGlobal.firstName}
								{" - "}
								{selectedChildGlobal.planYear[`description${lang}`]}
							</Typography>
						</div>
					) : (
						<>Ningun estudiante seleccionado</>
					)}

					<div className="automated-planning-from-date">
						<label>
							<Typography variant="h6">Planear desde:</Typography>{" "}
						</label>{" "}
						<div
							title="Fecha en la que inicia la planeacion automatica"
							className="automated-planning-date-input"
							onClick={() => {
								setShowFromDateDateTimePicker(true);
							}}
						>
							<Typography>
								<div className="automated-planning-input-text">
									{Moment(dateFrom).format("DD/MM/YYYY")}
								</div>
							</Typography>
						</div>
					</div>
					<div className="automated-planning-weeks-select">
						<label htmlFor="weeks">
							<Typography variant="h6">Numero de semanas:</Typography>{" "}
						</label>{" "}
						<Typography>
							<select
								title="Numero de semanas a planear"
								id="weeks"
								value={numberOfWeeks}
								onChange={(e) => setNumberOfWeeks(parseInt(e.target.value))}
							>
								{Array.from({ length: 15 }, (_, i) => i + 1).map((num) => (
									<option key={num} value={num}>
										{num}
									</option>
								))}
							</select>
						</Typography>
					</div>
					<div className="automated-planning-clear-btn-container">
						<span
							className="automated-planning-clear-btn hover-brighten"
							onClick={() => {
								clearAllCheckboxes(true);
							}}
						>
							Limpiar seleccion
						</span>
					</div>

					<table>
						<thead>
							<tr>
								<th></th>
								<th>
									<Typography variant="tableRow">
										{GetPrompts("Monday Text").slice(0, 3)}
									</Typography>
								</th>
								<th>
									<Typography variant="tableRow">
										{GetPrompts("Tuesday Text").slice(0, 3)}
									</Typography>
								</th>
								<th>
									<Typography variant="tableRow">
										{GetPrompts("Wednesday Text").slice(0, 3)}
									</Typography>
								</th>
								<th>
									<Typography variant="tableRow">
										{GetPrompts("Thursday Text").slice(0, 3)}
									</Typography>
								</th>
								<th>
									<Typography variant="tableRow">
										{GetPrompts("Friday Text").slice(0, 3)}
									</Typography>
								</th>
								<th>
									<Typography variant="tableRow">
										{GetPrompts("Saturday Text").slice(0, 3)}
									</Typography>
								</th>
								<th>
									<Typography variant="tableRow">
										{GetPrompts("Sunday Text").slice(0, 3)}
									</Typography>
								</th>
							</tr>
						</thead>
						<tbody>
							{activities.map((activity) => (
								<tr key={activity.activityId}>
									<td>
										{" "}
										<Typography variant="tableRow">
											{activity.isCustomActivity === true ? "* " : ""}
											{activity.description}
										</Typography>{" "}
									</td>
									{[
										"monday",
										"tuesday",
										"wednesday",
										"thursday",
										"friday",
										"saturday",
										"sunday",
									].map((day) => (
										<td key={day}>
											<input
												type="checkbox"
												checked={activity[day]}
												onChange={() =>
													handleCheckboxChange(activity.activityId, day)
												}
											/>
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
					<div
						className={`automated-planning-save-btn hover-brighten ${
							selectedChildGlobal === undefined ? "disabled" : ""
						}`}
						onClick={() => {
							setShowConfirmation(true);
						}}
					>
						Guardar
					</div>
				</PerfectScrollbar>
			</ModalComponent>

			<DatePickerPopUp
				open={showFromDateDateTimePicker}
				close={() => {
					setShowFromDateDateTimePicker(false);
				}}
				onChange={(e) => {
					setDateDateFrom(e);
					var newDate = new Date(e);
					setDateDateFrom(newDate);
				}}
			/>
			<YesNoConfirm
				dlgOpen={showConfirmation}
				callBackFunction={handleSaveChanges}
				onClose={() => setShowConfirmation(false)}
				confirmMessage={
					"Seguro que quieres realizar esta planeacion automatica?"
				}
			/>
		</>
	);
}
