import React from "react";
import * as IoIcons from "react-icons/io";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import { GetProductPicture } from "../ProductsPictures/ProductsPictures";
import "./ProductHome_Card.css";
import { useState } from "react";
import { Typography } from "@mui/material";

export default function ProductHome_Card({
	product,
	navigate,
	setHomeNavBarIsOpen,
}) {
	const lang = sessionStorage.getItem("_lang");
	const { picturePath, navPath, productId } = product;
	const [isExpanded, setIsExpanded] = useState(false);

	const GoToModule = (productId) => {
		if (IsActiveDuringLaunching(productId)) {
			navigate(`/${navPath}`);
		}
	};
	const IsActiveDuringLaunching = (productId) => {
		if (productId == 2) {
			return true;
		}

		return false;
	};
	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};

	const maxLengthToDisplay = 300;
	const truncatedText = isExpanded
		? product[`description${lang}`]
		: product[`description${lang}`].slice(0, maxLengthToDisplay);

	return (
		<div className="product-home-card-container">
			<div className="product-home-card-status-container">
				{IsActiveDuringLaunching(productId) ? (
					<>
						<div className="active-product-status base-button-text">
							<span>
								<IoIcons.IoMdCheckmark />
							</span>
							{GetPrompts("Product Active")}
						</div>
					</>
				) : (
					<>
						<div className="soon-product-status">
							{GetPrompts("Product Soon")}
						</div>
					</>
				)}
			</div>

			<div
				className="product-home-card-picture"
				onClick={() => {
					GoToModule(productId);
				}}
			>
				<img src={GetProductPicture(productId)} alt="" />
			</div>
			<div className="product-home-card-title">
				<Typography variant="baseTitle">
					{product[`productTitle${lang}`]}
				</Typography>
			</div>
			<div className="product-home-card-desciption base-parafraph">
				<Typography variant="bodyParagraph">
					<span
						onClick={() => {
							GoToModule(productId);
						}}
					>
						{truncatedText}
					</span>

					{product[`description${lang}`].length > maxLengthToDisplay && (
						<div className="show-more-btn" onClick={toggleText}>
							...
							{isExpanded ? GetPrompts("Show less") : GetPrompts("Show more")}
						</div>
					)}
				</Typography>
			</div>
		</div>
	);
}
