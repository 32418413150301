import Moment from "moment";
import axios from "../../../axios";
export const EditExistingPlannedActivityByDateRangeByChild = async ({
	plannerId,
	fromDate,
	toDate,
	fromHour,
	fromMinute,
	toHour,
	toMinute,
	comments,
	childrenToAddList,
	childrenToRemoveList,
	editDateRange,
	activityDateChange,
	formattedComments,
	thirdPartySelectedUserId = 0,
}) => {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/EditExistingPlannedActivityByDateRangeByChild"
				: "Planner/api/v1/EditThirdPartyLinkedUserExistingPlannedActivityByDateRangeByChild";

		const response = await axios.post(url, {
			UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			PlannerId: plannerId,
			FromDate: Moment(fromDate).format("YYYY-MM-DDTHH:mm:ss"),
			ToDate: Moment(toDate).format("YYYY-MM-DDTHH:mm:ss"),
			FromHour: parseInt(Moment(fromHour).format("HH")),
			FromMinute: parseInt(Moment(fromHour).format("m")),
			ToHour: parseInt(Moment(toHour).format("HH")),
			ToMinute: parseInt(Moment(toHour).format("m")),
			Comments: comments,
			FormattedComments: formattedComments,
			ChildrenToAddList: childrenToAddList,
			ChildrenToRemoveList: childrenToRemoveList,
			EditDateRange: editDateRange,
			ActivityDateChange: Moment(activityDateChange).format(
				"YYYY-MM-DDTHH:mm:ss"
			),
		});
		return response;
	} catch (error) {}
};
