import customAxios from "../../../axios";

export async function GetThirdPartyLinkedUserChildren({ userId }) {
	const url = "PlannerAdmin/api/v1/GetThirdPartyLinkedUserChildren";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: userId,
			},
		});
		return response.data;
	} catch (error) {}
}
