import React, { useContext, useState } from "react";
import * as IoIcons from "react-icons/io";
import InfoPopup from "../../Components/InfoPopup/InfoPopup";
import { RESPONSIVE_MODAL_STYLES } from "../../Components/Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";

import "./CustomerSupport.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CustomerSupport_ModalStyle";
import { SendCustomerSupportMessage } from "./SendCustomerSupportMessage";
import { Typography } from "@mui/material";

export function CustomerSupportIcon({ isOverallMenu = false }) {
	const { contactUsIsOpen, setContactUsIsOpen } = useContext(Context);

	return (
		<>
			<div
				className="customer-suport-sidebar-icon"
				onClick={() => {
					setContactUsIsOpen(true);
				}}
			>
				<IoIcons.IoMdHelpCircle />
				<span
					className={`sidebar-item-text ${
						isOverallMenu ? "overall-sidebar-menu-item" : "sidebar-menu-item"
					}`}
				>
					{GetPrompts("Sidebar Technical Support")}
				</span>
			</div>
		</>
	);
}

export function CustomerSupportIcon_Home() {
	const { contactUsIsOpen, setContactUsIsOpen } = useContext(Context);
	return (
		<>
			<div
				className="customer-suport-sidebar-icon-container"
				onClick={() => {
					setContactUsIsOpen(true);
				}}
			>
				<div className="customer-suport-sidebar-icon-container-icon">
					<IoIcons.IoMdHelpCircle />
				</div>
				<div className="customer-suport-sidebar-icon-container-text sidebar-menu-item">
					{GetPrompts("Sidebar Technical Support")}
				</div>
			</div>
		</>
	);
}

export function CustomerSupportModal() {
	const { contactUsIsOpen, setContactUsIsOpen } = useContext(Context);
	const [infoPopupIsOpen, setInfoPopupIsOpen] = useState(false);
	const [infoMessage, setInfoMessage] = useState(
		GetPrompts("Technical Support Sent")
	);

	const [message, setMessage] = useState("");

	const handleChange = (e) => {
		setMessage(e.target.value);
	};

	const CloseAll = () => {
		setContactUsIsOpen(false);
		setInfoPopupIsOpen(false);
	};
	const Send = () => {
		if (message == "") {
			setContactUsIsOpen(false);
		} else {
			SendCustomerSupportMessage(message).then((response) => {
				setInfoMessage(GetPrompts("Technical Support Sent"));
				setMessage("");
				setInfoPopupIsOpen(true);
			});
		}
	};

	return (
		<>
			{contactUsIsOpen ? (
				<div>
					<div
						style={OVERLAY_STYLES}
						onClick={() => setContactUsIsOpen(false)}
					></div>

					<div
						style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
						className="container customer-support-container"
					>
						<div
							className="customer-support-close-btn"
							onClick={() => setContactUsIsOpen(false)}
						>
							X
						</div>
						<div className="customer-support-title">
							<Typography variant="bodyParagraph">
								{GetPrompts("Technical Support Title")}
							</Typography>
						</div>
						<div className="customer-support-text">
							<Typography variant="bodyParagraph">
								<textarea
									rows={9}
									// cols={30}
									className="customer-support-text-area"
									placeholder={GetPrompts("Technical Support Place Holder")}
									onChange={handleChange}
									value={message}
								/>
							</Typography>
						</div>
						<button
							className="customer-support-send-btn"
							onClick={() => {
								Send();
							}}
						>
							{GetPrompts("Technical Support Send")}
						</button>
					</div>
				</div>
			) : (
				""
			)}

			<InfoPopup
				icon={<IoIcons.IoMdCheckbox />}
				open={infoPopupIsOpen}
				onClose={() => CloseAll()}
				errorMessage={infoMessage}
			/>
		</>
	);
}
