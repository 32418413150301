import React, { useContext, useEffect, useState } from "react";
import "./SelectActivityCheckbox.css";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../Context/Contexts";

export default function SelectActivityCheckbox({
	activity,
	addToActivitiesInEditionList,
	show = false,
}) {
	const { activitiesInEditionList } = useContext(Context);

	const isSelectedForEdition = (id) => {
		return activitiesInEditionList.some((item) => item.plannerId === id);
	};

	return (
		<>
			<div
				className="select-activity-checkbox-container do-not-print"
				title="Seleccionar actividad para edicion"
			>
				<input
					type="checkbox"
					className={`select-activity-checkbox${
						show == true || isSelectedForEdition(activity.plannerId) === true
							? "-show"
							: ""
					}`}
					checked={isSelectedForEdition(activity.plannerId)}
					onChange={(e) => {}}
					onClick={async (e) => {
						await addToActivitiesInEditionList(activity.plannerId);
					}}
				/>
			</div>
		</>
	);
}
