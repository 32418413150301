import React, { useEffect, useState } from "react";
import "./DoneComponentTransactionRetries.css";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import logo from "../../Assets/Logo.svg";
import * as IoIcons from "react-icons/io";
import { useContext } from "react";
import { Context } from "../../Context/Contexts";
import { css } from "aphrodite";
import { animationStyles } from "../Animations/Styles";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./DoneComponentTransactionRetries_ModalStyle";

export default function DoneComponentTransactionRetries() {
	var secondsToClose = 2;
	const [isMounted, setIsMounted] = useState(false);
	const {
		showDoneComponentMaxTransactionRetries,
		setShowDoneComponentMaxTransactionRetries,
	} = useContext(Context);
	const [logoFadeIn, setLogoFadeIn] = useState(false);
	const [checkFadeIn, setCheckFadeIn] = useState(false);

	useEffect(() => {
		if (showDoneComponentMaxTransactionRetries) {
			setTimeout(() => {
				setLogoFadeIn(true);
			}, 400);

			setTimeout(() => {
				setCheckFadeIn(true);
			}, 800);

			setTimeout(() => {
				setShowDoneComponentMaxTransactionRetries(false);
			}, secondsToClose * 2000);
		}
	}, [showDoneComponentMaxTransactionRetries]);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!showDoneComponentMaxTransactionRetries) return;

	return (
		<>
			<div
				style={OVERLAY_STYLES}
				onClick={() => {
					setShowDoneComponentMaxTransactionRetries(false);
				}}
			></div>

			<div
				className="done-component-transaction-retries-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className={
						css(isMounted ? animationStyles.bounce : null) +
						` done-component-transaction-retries-circle-grid done-component-transaction-retries-circle ${
							logoFadeIn
								? "done-component-transaction-retries-fade-in"
								: "done-component-transaction-retries-fade-out"
						}`
					}
				>
					<img
						src={logo}
						className="done-component-transaction-retries-logo"
						alt="done-component-transaction-retries-logo"
					/>
					<span
						className={`done-component-transaction-retries-check ${
							checkFadeIn
								? "done-component-transaction-retries-fade-in"
								: "done-component-transaction-retries-fade-out"
						}`}
					>
						<IoIcons.IoMdAlert />
					</span>
				</div>
			</div>
		</>
	);
}
