import React, { useContext, useState } from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import { MODAL_STYLES, OVERLAY_STYLES } from "./DuplicateWeekModal_ModalStyles";
import YesNoConfirm from "../ModalConfirm/YesNoConfirm";
import "./DuplicateWeekModal.css";
import { DuplicateWeekToAnotherWeek } from "../Planner/Actions/DuplicateWeek";
import DatePickerPopUp from "../DatePicker/DatePickerPopUp";
import Moment from "moment";
import { Context } from "../../Context/Contexts";
import * as IoIcons from "react-icons/io";
import { MEMBERSHIP_UPGRADE_NEEDED } from "../Constants/Constants";
import YesNoModal from "../YesNoModal/YesNoModal";
import { Typography } from "@mui/material";
import ErrorPopUp from "../Errors/ErrorPopUp";

export default function DuplicateWeekModal({
	isOpen,
	close,
	weeklyScheduleDate,
}) {
	const { setShowIsloading, setShowIsloadingMessage } = useContext(Context);
	const { setShowMembershipNeedUpgrade } = useContext(Context);
	const { setRefreshWeeklySchedule } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { selectedChildGlobal } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);

	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showDeleteExistingConfirmation, setShowDeleteExistingConfirmation] =
		useState(false);

	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationCallBack, setConfirmationCallBack] = useState();

	const [showDatePicker, setShowDatePicker] = useState(false);
	const [destinationCalendarWeek, setDestinationCalendarWeek] = useState(
		new Date()
	);
	const [deleteExistingActivities, setDeleteExistingActivities] =
		useState(false);
	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const CloseClickHandler = () => {
		setDestinationCalendarWeek(new Date());
		close();
	};
	const DeleteExistingConfirmation = () => {
		setShowDeleteExistingConfirmation(true);
	};
	const SetupFinalConfirmation = () => {
		setShowConfirmation(true);
		setShowDeleteExistingConfirmation(false);
	};

	const Duplicate = () => {
		let childId =
			selectedChildGlobal != undefined ? selectedChildGlobal.planChildId : 0;
		var calendarWeek = Moment(weeklyScheduleDate).format("YYYY-MM-DDTHH:mm:ss");

		setShowIsloading(true);

		DuplicateWeekToAnotherWeek({
			calendarWeek: calendarWeek,
			destinationCalendarWeek: destinationCalendarWeek,
			deleteExistingActivities: deleteExistingActivities,
			childId: childId,
			thirdPartySelectedUserId: thirdPartySelectedUserId,
		}).then((response) => {
			if (response.errors === MEMBERSHIP_UPGRADE_NEEDED) {
				setShowMembershipNeedUpgrade(true);
				setShowIsloading(false);
				setShowConfirmation(false);
				setDestinationCalendarWeek(new Date());
				close();
				return;
			}

			setRefreshWeeklySchedule(true);
			setShowDonecomponent(true);
			setShowIsloading(false);
			setDestinationCalendarWeek(new Date());
			close();
		});
	};

	const DateEditionHandler = (selectedDate) => {
		const selectedMoment = Moment(selectedDate);
		const currentWeekSunday = Moment(weeklyScheduleDate).endOf("week");

		if (!selectedMoment.isAfter(currentWeekSunday)) {
			setErrorMessage("La fecha seleccionada debe ser despues de esta semana.");
			setErrorPopUpIsOpen(true);
			return;
		}

		setDestinationCalendarWeek(selectedMoment.format("YYYY-MM-DDTHH:mm:ss"));
	};

	return (
		<>
			<ModalComponent
				isOpen={isOpen}
				onClose={CloseClickHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
				showBackgroundImage={false}
			>
				<div className="duplicate-week-container">
					<div className="duplicate-week-close-btn" onClick={CloseClickHandler}>
						X
					</div>
					<div className="duplicate-week-body">
						<div className="duplicate-week-text">
							<Typography variant="body1">
								Escoge la semana que va a recibir las actividades que quieres
								duplicar
							</Typography>
						</div>
						<div>
							<div
								className="duplicate-week-date-input hover-brighten"
								onClick={() => {
									setShowDatePicker(true);
								}}
							>
								<Typography variant="body2">
									{Moment(destinationCalendarWeek).format("DD-MMM-YYYY")}
								</Typography>
							</div>
						</div>
					</div>
					<div className="duplicate-week-btn-container">
						<div
							className="duplicate-week-btn hover-brighten"
							onClick={(event) => {
								event.stopPropagation();

								DeleteExistingConfirmation();
							}}
							title="Duplicar semana"
						>
							Continuar
						</div>
					</div>
				</div>
			</ModalComponent>

			<YesNoModal
				dlgOpen={showDeleteExistingConfirmation}
				onYes={() => {
					setDeleteExistingActivities(true);
					SetupFinalConfirmation();
				}}
				onNo={() => {
					setDeleteExistingActivities(false);
					SetupFinalConfirmation();
				}}
				confirmMessage={
					"Deseas sobreescribir las actividades que ya existen en esa semana?"
				}
			/>

			<YesNoConfirm
				dlgOpen={showConfirmation}
				callBackFunction={() => {
					Duplicate();
					setShowConfirmation(false);
				}}
				onClose={() => {
					setConfirmationCallBack(() => {});
					setShowConfirmation(false);
					close();
				}}
				confirmMessage={"Entendido, seguro que quieres duplicar esta semana?"}
			/>
			<DatePickerPopUp
				open={showDatePicker}
				close={() => {
					setShowDatePicker(false);
				}}
				onChange={DateEditionHandler}
			/>
			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
}
