import customAxios from "../../axios";

export async function RevertPlannerAction({ thirdPartySelectedUserId = 0 }) {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/RevertAction"
				: "Planner/api/v1/RevertActionForThirdPartyAdmin";

		const response = await customAxios.post(url, {
			UserId: thirdPartySelectedUserId,
		});
		return response.data;
	} catch (error) {}
}
