import axios from "../../../../axios";
export const MarkPlannedActivityAsPending = async ({ plannerId }) => {
	try {
		const url = `Planner/api/v1/MarkPlannedActivityAsPending`;

		const response = await axios.post(url, {
			UserId: 0,
			PlannerId: plannerId,
		});
		return response.data;
	} catch (error) {}
};
