import axios from "../../axios";
export async function GetAllThirdPartyLinkedUserChildren({ userId }) {
	const url = "Child/api/v1/GetAllThirdPartyLinkedUserChildren";
	try {
		const response = await axios.get(url, {
			params: {
				UserId: userId,
			},
		});
		return response.data;
	} catch (error) {}
}
