import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { Context } from "../../Context/Contexts";
import Pagination from "../Pagination/Pagination";
import { GetPrompts } from "../Prompts/PromptsComponent";
import ChildCard from "./ChildCard";
import ChildCard_Edit from "./ChildCard_Edit";
import { GetChildrenDatatable } from "./Datatable/GetChildrenDatatable";
import { EmptyChild } from "./EmptyChild";
import { GetChildren } from "./GetChildren";
import { GetFilteredChildren } from "./GetFilteredChildren";

export default function ChildrenList() {
	const { isloading, setIsloading } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);
	const [showDatatable, setShowDatatable] = useState(false);

	const [userId, setUserId] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalPages, SetTotalPages] = useState(0);

	const [showFilter, setShowFilter] = useState(false);
	const [filterByFirstName, setFilterByFirstName] = useState("");
	const [filterByLastName, setFilterByLastName] = useState("");
	const [filterByIdentification, setFilterByIdentification] = useState("");

	const [childInEdition, setChildInEdition] = useState("");

	const [isOpen, setIsOpen] = useState(false);
	const [childrenList, setChildrenList] = useState([]);

	const NewChildClickHandler = () => {
		const emptyChild = EmptyChild;
		emptyChild.userId = userId;

		setChildInEdition(emptyChild);
		setIsOpen(true);
	};

	useEffect(() => {
		if (
			filterByFirstName != "" ||
			filterByLastName != "" ||
			filterByIdentification != ""
		) {
			FeedFilteredChildrenList();
		} else {
			FeedChildrenList();
		}
	}, [currentPage]);

	useEffect(() => {
		if (
			filterByFirstName == "" &&
			filterByLastName == "" &&
			filterByIdentification == ""
		) {
			FeedFilteredChildrenList();
		}
	}, [filterByFirstName, filterByLastName, filterByIdentification]);

	const FeedChildrenList = () => {
		GetChildren({
			currentPage,
			itemsPerPage,
			thirdPartySelectedUserId: thirdPartySelectedUserId,
		}).then(function (response) {
			setCurrentPage(response.currentPage);
			SetTotalPages(response.pages);
			setChildrenList(response.result);
		});
	};

	const FeedFilteredChildrenList = () => {
		GetFilteredChildren({
			currentPage,
			itemsPerPage,
			filterByFirstName,
			filterByLastName,
			filterByIdentification,
		}).then(function (response) {
			SetTotalPages(response.pages);

			setChildrenList(response.result);
		});
	};

	const HandleKeyDown = (e) => {
		if (e.key == "Enter") {
			FeedFilteredChildrenList();
		}
	};

	function GetListCards() {
		{
			return childrenList.map((child) => {
				return ChildCard({
					child,
					setChildInEdition,
					setIsOpen,
				});
			});
		}
	}
	return (
		<>
			<div className="container">
				<div className="page-menu-container">
					<div
						className="child-filter-button-btn"
						onClick={() => {
							setShowFilter(!showFilter);
						}}
					>
						<FontAwesomeIcon icon={faFilter} /> {showFilter}
					</div>

					<div className="add-child-button-btn" onClick={NewChildClickHandler}>
						<IoIcons.IoMdPersonAdd />
					</div>
					<div
						className="change-view-btn"
						onClick={() => {
							setShowDatatable(!showDatatable);
						}}
					>
						<RiIcons.RiTableAltLine />
					</div>
				</div>

				{showFilter ? (
					<>
						<div
							className="children-filters-container"
							onKeyDown={HandleKeyDown}
						>
							<div className="filter-child">
								{GetPrompts("Id Text")}:{" "}
								<input
									type="text"
									className="filter-input"
									onChange={(e) => {
										setFilterByIdentification(e.target.value);
									}}
								/>
							</div>

							<div className="filter-assignment">
								{GetPrompts("Name text")}:{" "}
								<input
									type="text"
									className="filter-input"
									onChange={(e) => setFilterByFirstName(e.target.value)}
								/>
							</div>

							<div className="filter-frecuency">
								{GetPrompts("Last Name text")}:{" "}
								<input
									type="text"
									className="filter-input"
									onChange={(e) => setFilterByLastName(e.target.value)}
								/>
							</div>
						</div>
					</>
				) : (
					<></>
				)}

				<div
					className={
						showDatatable
							? "container datatable-container"
							: "container children-container"
					}
				>
					{childrenList ? (
						<>
							{showDatatable ? (
								<>
									{GetChildrenDatatable({
										childrenList,
										setChildInEdition,
										setIsOpen,
									})}
								</>
							) : (
								<>{GetListCards()}</>
							)}
						</>
					) : (
						<></>
					)}
				</div>

				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					setCurrentPage={setCurrentPage}
				/>
			</div>

			<ChildCard_Edit
				open={isOpen}
				child={childInEdition}
				onClose={() => setIsOpen(false)}
				getChildren={FeedChildrenList}
				setUserId={setUserId}
			/>
		</>
	);
}
