import axios from "../../axios";
export async function GetChildren({
	currentPage = 1,
	itemsPerPage = 10,
	thirdPartySelectedUserId = 0,
}) {
	const url =
		thirdPartySelectedUserId === 0
			? "Child/api/v1/GetAllChildPaged"
			: "Child/api/v1/GetAllThirdPartyLinkedUserChildPaged";
	try {
		const response = await axios.get(url, {
			params: {
				CurrentPage: currentPage,
				ItemsPerPage: itemsPerPage,
				UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			},
		});
		return response.data;
	} catch (error) {}
}
