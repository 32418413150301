import React, { useContext, useState } from "react";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "../ThirdPartySelectedUserOptions/ThirdPartySelectedUserOptions_ModalStyle";
import { Context } from "../../../Context/Contexts";
import ModalComponent from "../../ModalComponent/ModalComponent";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import "./ThirdPartyCreateUser.css";
import { Typography } from "@mui/material";
import { CreateNewUserLinkedToThirdParty } from "./CreateNewThirdPartyUser";
import InfoPopup from "../../InfoPopup/InfoPopup";
import * as IoIcons from "react-icons/io";

export default function ThirdPartyCreateUser() {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { showThirdPartyCreateUser, setShowThirdPartyCreateUser } =
		useContext(Context);
	const { setRefreshThirdPartyAdminModule } = useContext(Context);
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");
	const [showPwd, setShowPwd] = useState(false);
	const [infoPopUpIsOpen, setInfoPopUpIsOpen] = useState(false);
	const [infoMessage, setInfoMessage] = useState("");

	const CloseClickHandler = () => {
		setShowThirdPartyCreateUser(false);
	};
	const SaveClickHandler = () => {
		if (name === "" || lastName === "" || email === "" || pwd === "") {
			setInfoMessage("Necesitamos todos los campos");
			setInfoPopUpIsOpen(true);
			return;
		}
		setShowIsloading(true);

		CreateNewUserLinkedToThirdParty({
			name: name,
			lastName: lastName,
			email: email,
			pwd: pwd,
		}).then((response) => {
			console.log(response);
			setShowIsloading(false);
			if (response.errors != null) {
				setInfoMessage(response.errors);
				setInfoPopUpIsOpen(true);
				return;
			}
			setShowDonecomponent(true);
			ResetForm();
			setRefreshThirdPartyAdminModule(true);
			CloseClickHandler();
		});
	};

	const ResetForm = () => {
		setName("");
		setLastName("");
		setEmail("");
		setPwd("");
	};

	if (!showThirdPartyCreateUser) return;

	return (
		<>
			<ModalComponent
				isOpen={showThirdPartyCreateUser}
				onClose={CloseClickHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
				showBackgroundImage={false}
			>
				<div
					className="third-party-create-user-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>

				<div className="third-party-create-user-container">
					<div className="third-party-create-user-form">
						<label className="third-party-create-user-form-label">
							<Typography variant="h6">{GetPrompts("Name text")}:</Typography>
						</label>
						<input
							className="third-party-create-user-form-input"
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>

						<label className="third-party-create-user-form-label">
							<Typography variant="h6">
								{GetPrompts("Last Name text")}:
							</Typography>
						</label>
						<input
							className="third-party-create-user-form-input"
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>

						<label className="third-party-create-user-form-label">
							<Typography variant="h6">{GetPrompts("Email text")}:</Typography>
						</label>
						<input
							className="third-party-create-user-form-input"
							type="text"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<label className="third-party-create-user-form-label">
							<Typography variant="h6">
								{GetPrompts("Login Pwd tag")}:
							</Typography>
						</label>
						<input
							className="third-party-create-user-form-input"
							type={showPwd ? "text" : "password"}
							onChange={(e) => setPwd(e.target.value)}
							value={pwd}
						/>
					</div>
					<div className="third-party-create-user-form-save-btn-container">
						<button
							className="third-party-create-user-form-save-btn hover-brighten"
							onClick={SaveClickHandler}
						>
							{GetPrompts("Save text")}
						</button>
					</div>
				</div>
			</ModalComponent>

			<InfoPopup
				icon={<IoIcons.IoMdAlert />}
				open={infoPopUpIsOpen}
				onClose={() => {
					setInfoPopUpIsOpen(false);
				}}
				errorMessage={infoMessage}
			/>
		</>
	);
}
