import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context/Contexts";
import { GetUsersByThirdPartyUserId } from "./GetUsersByThirdPartyUserId";
import "./ThirdPartyAdminUsersList.css";
import ThirdPartySelectedUserOptions from "../ThirdPartySelectedUserOptions/ThirdPartySelectedUserOptions";
import * as IoIcons from "react-icons/io";
import { Typography } from "@mui/material";
import ChildrenAdmin from "../../Children/ChildrenAdmin/ChildrenAdmin";
import freeMembershipLogo from "../../../Assets/MembershipIcons/Free_Membership_Logo.jpg";
import basicMembershipLogo from "../../../Assets/MembershipIcons/Basic_Membership_Logo.jpg";
import premiumMembershipLogo from "../../../Assets/MembershipIcons/Premium_Membership_Logo.jpg";
import moment from "moment";
import ThirdPartyAdminApplyPlan from "../ThirdPartyAdminApplyPlan/ThirdPartyAdminApplyPlan";

export default function ThirdPartyAdminUsersList() {
	var loadCount = 0;
	const { setShowIsloading } = useContext(Context);
	const { thirdPartySelectedUserId, setThirdPartySelectedUserId } =
		useContext(Context);
	const { refreshThirdPartyAdminModule, setRefreshThirdPartyAdminModule } =
		useContext(Context);
	const [usersList, setUsersList] = useState([]);
	const [filter, setFilter] = useState("");
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [showAdminApplyPlan, setShowAdminApplyPlan] = useState(false);
	const [
		showThirdPartySelectedUserOptions,
		setShowThirdPartySelectedUserOptions,
	] = useState(false);
	const [showChildrenAdmin, setShowChildrenAdmin] = useState(false);

	const FeedUsersList = () => {
		setShowIsloading(true);
		GetUsersByThirdPartyUserId().then((response) => {
			console.log(response);
			setUsersList(response.result);
			setFilteredUsers(response.result);
			setShowIsloading(false);
		});
		setShowIsloading(false);
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		FeedUsersList();
		loadCount = loadCount + 1;
	};
	useEffect(() => {
		if (refreshThirdPartyAdminModule === true) {
			setRefreshThirdPartyAdminModule(false);
			FeedUsersList();
		}
	}, [refreshThirdPartyAdminModule]);
	useEffect(() => {
		console.log(filter);
		const lowerCaseFilter = filter.toLowerCase();
		const filtered = usersList.filter(
			(user) =>
				user.firstName.toLowerCase().includes(lowerCaseFilter) ||
				user.lastName.toLowerCase().includes(lowerCaseFilter) ||
				(user.username && user.username.toLowerCase().includes(lowerCaseFilter))
		);
		setFilteredUsers(filtered);
	}, [filter, usersList]);

	return (
		<>
			<div className="third-party-admin-users-list-container">
				<div className="third-party-admin-users-list-filter-container">
					<input
						type="text"
						placeholder="Buscar"
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
						className="third-party-admin-users-list-filter"
					/>
				</div>

				<table className="third-party-admin-users-list-table-container">
					<thead>
						<tr>
							<th></th>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Membresia</th>

							<th></th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filteredUsers.map((user) => {
							const isExpiringSoon =
								user.membershipTypeId === 2 &&
								moment(user.membershipExpirationDate).isBefore(
									moment().add(1, "month")
								);

							return (
								<>
									<tr key={user.userId}>
										<td className="third-party-admin-users-list-member-icon">
											{user.membershipTypeId === 2 ? (
												<IoIcons.IoIosCheckmarkCircle title="Tiene membresia" />
											) : (
												<></>
											)}
										</td>
										<td>
											<Typography variant="tableRow">
												{user.firstName}
											</Typography>
										</td>
										<td>
											<Typography variant="tableRow">
												{user.lastName}
											</Typography>
										</td>
										<td>
											<Typography
												variant="tableRow"
												style={{ color: isExpiringSoon ? "red" : "inherit" }}
											>
												{user.membershipTypeId === 2 ? (
													moment(user.membershipExpirationDate).format(
														"DD-MMM-YYYY"
													)
												) : (
													<></>
												)}
											</Typography>
										</td>

										<td>
											<div
												className="third-party-admin-users-list-action-btn"
												onClick={() => {
													setThirdPartySelectedUserId(user.userId);
													setShowThirdPartySelectedUserOptions(true);
												}}
											>
												<Typography variant="caption">Acciones</Typography>
											</div>
										</td>
										<td>
											<div
												className="third-party-admin-users-list-action-btn"
												onClick={() => {
													setThirdPartySelectedUserId(user.userId);
												}}
											>
												<Typography variant="caption">Editar</Typography>
											</div>
										</td>

										<td>
											<div
												title="Estudiantes"
												className="third-party-admin-users-list-students-btn"
												onClick={() => {
													setThirdPartySelectedUserId(user.userId);
													setShowChildrenAdmin(true);
												}}
											>
												<Typography variant="caption">Estudiantes</Typography>
											</div>
										</td>
										<td>
											<div
												className="third-party-admin-users-list-action-btn"
												onClick={() => {
													setThirdPartySelectedUserId(user.userId);
													setShowAdminApplyPlan(true);
												}}
											>
												<Typography variant="caption">Aplicar plan</Typography>
											</div>
										</td>
									</tr>
								</>
							);
						})}
					</tbody>
				</table>
			</div>

			<ThirdPartySelectedUserOptions
				isOpen={showThirdPartySelectedUserOptions}
				close={() => setShowThirdPartySelectedUserOptions(false)}
			/>
			<ChildrenAdmin
				open={showChildrenAdmin}
				close={() => {
					setShowChildrenAdmin(false);
				}}
				isThirdPartyAdmin={true}
			/>

			<ThirdPartyAdminApplyPlan
				isOpen={showAdminApplyPlan}
				close={() => {
					setShowAdminApplyPlan(false);
				}}
			/>
		</>
	);
}
