import Moment from "moment";
import axios from "../../../../axios";
export async function GetWeeklyScheduleListAll({
	weeklyScheduleDate = "08-10-2022 07:00:00",
	childId = 0,
	thirdPartySelectedUserId = 0,
}) {
	const url =
		thirdPartySelectedUserId === 0
			? "Planner/api/v1/GetWeeklyScheduleAll"
			: "Planner/api/v1/GetThirdPartyUserLinkedWeeklyScheduleAll";
	try {
		const response = await axios.get(url, {
			params: {
				UserId: 0,
				CurrentDate: Moment(weeklyScheduleDate).format("MM/DD/YYYY"),
				ChildId: childId,
				UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			},
		});

		return response.data.result;
	} catch (error) {}
}
