import Moment from "moment";
import { useContext, useEffect, useState } from "react";
import axios from "../../../axios";
import { Context } from "../../../Context/Contexts";
import { MODAL_STYLES, OVERLAY_STYLES } from "./NewPlannedActivity_ModalStyle";

import DateTimePicker from "react-datetime-picker";
import Spinner from "react-spinkit";
import { ColoredLine } from "../../ColoredLine/ColoredLine";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { SPINNER_STYLES_ORANGE } from "../../Spinner/SpinnerStyles";
import { CreateNewPlannedActivity } from "../Actions/CreateNewPlannedActivity";
import WeekdaysSelector from "../WeekdaysSelector/WeekdaysSelector";
import "./NewPlannedActivity.css";

export default function NewPlannedActivity({
	open,
	plannedActivity,
	onClose,
	GetPlannedActivities,
}) {
	const { isloading, setIsloading } = useContext(Context);
	const { setShowIsloading } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);

	const userId = plannedActivity.userId;
	const [dlgOpen, setDlgOpen] = useState(false);
	const [description, setDescription] = useState();
	const [plannedDate, setPlannedDate] = useState();
	const [fromTime, setFromTime] = useState();
	const [toTime, setToTime] = useState();
	const [comment, setComment] = useState("");

	const [newPlannedActivity, setNewPlannedActivity] = useState();

	const [newPlannedChild, setNewPlannedChild] = useState();

	const [newPlannedActivityFrequency, setNewPlannedActivityFrequency] =
		useState();
	const [newPlannedActivityFromDate, setNewPlannedActivityFromDate] = useState(
		new Date()
	);
	const [newPlannedActivityToDate, setNewPlannedActivityToDate] = useState(
		new Date()
	);
	const [newPlannedActivityFromTime, setNewPlannedActivityFromTime] = useState(
		new Date()
	);
	const [newPlannedActivityToTime, setNewPlannedActivityToTime] = useState(
		new Date()
	);

	const [plannedDatePicker, setPlannedDatePicker] = useState(new Date());
	const [fromTimeDatePicker, setFromTimeDatePicker] = useState(new Date());
	const [toTimeDatePicker, setToTimeDatePicker] = useState(new Date());

	const [childrenList, setChildrenList] = useState([]);
	const [activitiesList, setActivitiesList] = useState([]);
	const [frequencyList, setFrequencyList] = useState([]);
	const [weekdaysSelection, setWeekdaysSelection] = useState();

	const FeedEditionVariables = async (e) => {
		if (open) {
			if (plannedActivity.description == null) {
				setNewPlannedActivityFrequency(0);
				PlannedDateEditionHandler(new Date());
				FromTimeEditionHandler(new Date());
				ToTimeEditionHandler(new Date());
			}
			setDescription(plannedActivity.description);
		}
	};

	const GetActivitiesDropdowns = async (e) => {
		try {
			if (open) {
				const url = `Planner/api/v1/GetPlannedActivitiesDropdowns`;

				setShowIsloading(true);

				const response = await axios.get(url);

				setShowIsloading(false);
				setActivitiesList(response.data.result.planActivities);
				setFrequencyList(response.data.result.frequencies);
				setChildrenList(response.data.result.children);
			}
		} catch (error) {
			setShowIsloading(false);
		}
	};

	useEffect(() => {
		GetActivitiesDropdowns();
		FeedEditionVariables();
	}, [open]);

	const CreateClickHandler = async () => {
		setShowIsloading(true);

		setDlgOpen(false);

		await CreateNewPlannedActivity(
			newPlannedActivity,
			newPlannedChild,
			newPlannedActivityFrequency,
			newPlannedActivityFromDate,
			newPlannedActivityToDate,
			newPlannedActivityFromTime,
			newPlannedActivityToTime,
			weekdaysSelection,
			comment,
			thirdPartySelectedUserId
		);

		await GetPlannedActivities();
		onClose();

		setShowIsloading(false);
	};

	const CloseClickHandler = () => {
		onClose();
	};

	const PlannedDateEditionHandler = (e) => {
		setPlannedDatePicker(e);
		setPlannedDate(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};
	const FromTimeEditionHandler = (e) => {
		setFromTimeDatePicker(e);
		setFromTime(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};
	const ToTimeEditionHandler = (e) => {
		setToTimeDatePicker(e);
		setToTime(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};

	const NewPlannedFromDateEditionHandler = (e) => {
		setNewPlannedActivityFromDate(new Date(e));
	};
	const NewPlannedToDateEditionHandler = (e) => {
		setNewPlannedActivityToDate(new Date(e));
	};
	const NewPlannedFromTimeEditionHandler = (e) => {
		setNewPlannedActivityFromTime(e);
	};
	const NewPlannedToTimeEditionHandler = (e) => {
		setNewPlannedActivityToTime(e);
	};

	const NewActivitySelectionHandler = (e) => {
		setNewPlannedActivity(e);
	};
	const NewChildSelectionHandler = (e) => {
		setNewPlannedChild(e);
	};

	const FrequencyEditionHandler = async (e) => {
		setNewPlannedActivityFrequency(e);
	};

	if (!open) return null;
	return (
		<article key={plannedActivity.plannerId}>
			<div style={OVERLAY_STYLES}></div>

			<div
				className="edit-planned-activity-card"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<>
					<div className="edit-planned-row-container">
						<div className="edit-planned-card-label edit-planned-child-dropdown-label">
							Estudiante:
						</div>
						<div className="edit-planned-child-dropdown-container">
							<select
								className="edit-planned-child-dropdown"
								placeholder={""}
								onChange={(e) => NewChildSelectionHandler(e.target.value)}
							>
								<option
									key={`plannedChildItem${"blank"}`}
									defaultValue={""}
									value={""}
								>
									{""}
								</option>

								{childrenList.map((childItem) => {
									return (
										<option
											key={`plannedChildItem${childItem.planChildId}`}
											value={childItem.planChildId}
										>
											{childItem.firstName}
										</option>
									);
								})}
							</select>
						</div>
					</div>

					<div className="edit-planned-row-container">
						<div className="edit-planned-card-label edit-planned-activity-dropdown-label">
							Actividad:
						</div>
						<div className="edit-planned-activity-dropdown-container">
							<select
								className="edit-planned-activity-dropdown"
								placeholder={""}
								onChange={(e) => NewActivitySelectionHandler(e.target.value)}
							>
								<option
									key={`plannedActivityItem${"blank"}`}
									defaultValue={""}
									value={""}
								>
									{""}
								</option>

								{activitiesList.map((plannedActivityItem) => {
									return (
										<option
											key={`plannedActivityItem${plannedActivityItem.activityId}`}
											value={plannedActivityItem.activityId}
										>
											{plannedActivityItem.description}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="edit-planned-row-container">
						<div className="edit-planned-card-label edit-new-planned-activity-frequency-label">
							Frecuencia:
						</div>
						<div className="edit-new-planned-activity-frequency-content-container">
							<select
								className="edit-new-planned-activity-frequency-dropdown"
								placeholder={""}
								onChange={(e) => FrequencyEditionHandler(e.target.value)}
							>
								<option
									key={`plannedActivityItem${"blank"}`}
									defaultValue={""}
									value={""}
								>
									{""}
								</option>

								{frequencyList.map((frequencyItem) => {
									return (
										<option
											key={`frequencyItem${frequencyItem.frequencyId}`}
											value={frequencyItem.frequencyId}
										>
											{frequencyItem.description}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					{newPlannedActivityFrequency == 2 ? (
						<div className="edit-planned-row-container">
							<div className="edit-new-planned-activity-WeekdaysSelector-content-container">
								<WeekdaysSelector setWeekdaysSelection={setWeekdaysSelection} />
							</div>
						</div>
					) : (
						<></>
					)}

					<div>
						<ColoredLine color="red" />
					</div>

					<div className="edit-planned-row-container">
						<div className="edit-planned-card-label edit-new-planned-activity-from-label">
							Inicia:
						</div>
						<div className="edit-new-planned-activity-from-date">
							<DateTimePicker
								className={"planner-datetime-picker"}
								onChange={(e) => NewPlannedFromDateEditionHandler(e)}
								format={"dd/MM/yyyy"}
								value={newPlannedActivityFromDate}
								clearIcon={null}
								calendarIcon={null}
							/>
						</div>
					</div>

					<div className="edit-planned-row-container">
						<div className="edit-planned-card-label edit-new-planned-activity-to-label">
							Termina:
						</div>
						<div className="edit-new-planned-activity-to-date">
							<DateTimePicker
								className={"planner-datetime-picker"}
								onChange={(e) => NewPlannedToDateEditionHandler(e)}
								format={"dd/MM/yyyy"}
								value={newPlannedActivityToDate}
								calendarIcon={null}
								clearIcon={null}
							/>
						</div>
					</div>

					<div>
						<ColoredLine color="red" />
					</div>

					<div className="edit-planned-row-container">
						<div className="edit-planned-card-label edit-new-planned-activity-from-time-label">
							Desde:
						</div>
						<div className="edit-new-planned-activity-from-time">
							<DateTimePicker
								className="planner-datetime-picker edit-new-planned-activity-from-time"
								onChange={(e) => NewPlannedFromTimeEditionHandler(e)}
								format={"hh:mm a"}
								value={newPlannedActivityFromTime}
								calendarIcon={null}
								clearIcon={null}
								disableClock={true}
							/>
						</div>
					</div>

					<div className="edit-planned-row-container">
						<div className="edit-planned-card-label edit-new-planned-activity-to-time-label">
							Hasta:
						</div>
						<div className="edit-new-planned-activity-to-time">
							<DateTimePicker
								className="planner-datetime-picker edit-new-planned-activity-to-time"
								onChange={(e) => NewPlannedToTimeEditionHandler(e)}
								format={"hh:mm a"}
								value={newPlannedActivityToTime}
								calendarIcon={null}
								clearIcon={null}
								disableClock={true}
							/>
						</div>
					</div>

					<div className="edit-planned-row-container edit-planned-row-container-comments">
						<div className="edit-planned-card-label edit-new-planned-activity-comment-label">
							Comentario:
						</div>
						<input
							type="text"
							className="edit-new-planned-activity-comment-content"
							placeholder={"Comentario"}
							onChange={(e) => setComment(e.target.value)}
						/>
					</div>
				</>

				<div className="edit-planned-row-container edit-planned-row-container-btn">
					<div
						className="basic-edition-btn edit-planned-activity-close-btn"
						onClick={CloseClickHandler}
					>
						Cerrar
					</div>
					<div
						className="basic-edition-btn edit-planned-activity-create-btn"
						onClick={CreateClickHandler}
					>
						Crear
					</div>
				</div>

				{isloading ? <Spinner style={SPINNER_STYLES_ORANGE} /> : ""}
			</div>
		</article>
	);
}
