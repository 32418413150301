import customAxios from "../../../../axios";

export const MarkMultiplePlannedActivitiesAsPending = async ({
	plannedActivitiesIdList,
}) => {
	try {
		const url = `Planner/api/v1/MarkMultiplePlannedActivitiesAsPending`;

		const response = await customAxios.post(url, {
			UserId: 0,
			PlannedActivitiesIdList: plannedActivitiesIdList,
		});
	} catch (error) {}
};
