import axios from "../../axios";
export async function AddThirdPartyLinkedUserMembershipToCart({ userId }) {
	const url = "Membership/api/v1/AddThirdPartyLinkedUserMembershipToCart";
	try {
		const response = await axios.put(url, {
			UserId: userId,
		});
		return response.data;
	} catch (error) {}
}
