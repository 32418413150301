import { useContext } from "react";
import { Context } from "../../../../../Context/Contexts";
import { GetPrompts } from "../../../../Prompts/PromptsComponent";
import "./PlanPerDayCustomBlockData.css";

export function WeekBlockHeaderData({
	periodTitle,
	periodNumber,
	weekNumber,
	weekTitle = undefined,
	activitySample = undefined,
}) {
	var weekTitleToShow =
		weekTitle !== undefined && weekTitle !== null && weekTitle !== ""
			? weekTitle
			: undefined;

	const { selectedChildGlobal } = useContext(Context);
	const lang = sessionStorage.getItem("_lang");
	return (
		<>
			<div className="WeekBlockHeaderData  base-parafraph">
				{selectedChildGlobal !== undefined
					? `${selectedChildGlobal.firstName} ${
							selectedChildGlobal.lastName
					  }, Año ${activitySample.yearId}
					  
					   ${
								weekNumber === 0
									? `${
											weekTitleToShow !== undefined
												? `, ${weekTitleToShow}`
												: ""
									  }`
									: `, ${periodTitle} ${periodNumber}, ${GetPrompts(
											"Week"
									  )} ${weekNumber}`
							}`
					: ""}
			</div>
		</>
	);
}
