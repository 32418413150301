import Moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePickerPopUp from "../../../DatePicker/DatePickerPopUp";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import Sidebar from "../../../Sidebar/Sidebar";
import { GetDateBasedPlanReport } from "./GetDateBasedPlanReport";
import PlanPerDayCard from "./PlanPerDayCard";
import "./PlanPerDayReport.css";
import { Context } from "../../../../Context/Contexts";

export default function PlanPerDayReport() {
	var loadCount = 0;
	const [daysList, setDaysList] = useState([]);
	const [fromDate, setFromDate] = useState(new Date());
	const [toDate, setToDate] = useState(new Date());
	const { thirdPartySelectedUserId } = useContext(Context);

	const [showFromDatePopUp, setShowFromDatePopUp] = useState(false);
	const [showToDatePopUp, setShowToDatePopUp] = useState(false);

	const GetReport = () => {
		GetDateBasedPlanReport({
			currentPage: 1,
			itemsPerPage: 1000,
			fromDate: fromDate,
			toDate: toDate,
			thirdPartySelectedUserId: thirdPartySelectedUserId,
		}).then((response) => {
			setDaysList(response);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetReport();
		loadCount = loadCount + 1;
	};

	return (
		<>
			<Sidebar />
			<div className="basic-page-title">{GetPrompts("Activities report")}</div>
			<div className="plan-per-day-report-container">
				<div className="plan-per-day-report-headers">
					<div className="plan-per-day-report-date-input-container">
						<div
							className="plan-per-day-report-date-input"
							onClick={() => {
								setShowFromDatePopUp(true);
							}}
						>
							<div className="child-edition-input-text">
								{GetPrompts("From Text")}{" "}
								{Moment(fromDate).format("DD-MMM-YYYY")}
							</div>
						</div>

						<div
							className="plan-per-day-report-date-input"
							onClick={() => {
								setShowToDatePopUp(true);
							}}
						>
							<div className="child-edition-input-text">
								{GetPrompts("To Text")} {Moment(toDate).format("DD-MMM-YYYY")}
							</div>
						</div>
					</div>

					<div className="plan-per-day-report-btn-container do-not-print">
						<div
							className="do-not-print plan-per-day-report-btn"
							onClick={() => {
								GetReport();
							}}
						>
							{GetPrompts("View report")}
						</div>

						<div
							className="do-not-print 
                plan-per-day-report-print-item-btn"
							onClick={() => {
								window.print();
							}}
						>
							{GetPrompts("Print Text")}
						</div>
					</div>
				</div>

				<div className="plan-per-day-report-content">
					{daysList.map((day) => {
						var lastActivityId = 0;
						var sameActivity = false;
						return (
							<>
								<div className="plan-per-day-report-content-card">
									<PlanPerDayCard dayItem={day} />
								</div>
							</>
						);
					})}
				</div>
			</div>

			<DatePickerPopUp
				open={showFromDatePopUp}
				close={() => {
					setShowFromDatePopUp(false);
				}}
				onChange={setFromDate}
			/>

			<DatePickerPopUp
				open={showToDatePopUp}
				close={() => {
					setShowToDatePopUp(false);
				}}
				onChange={setToDate}
			/>
		</>
	);
}
