import customAxios from "../../../../axios";

export async function GetWeekInfo({
	requestedDate,
	thirdPartySelectedUserId = 0,
}) {
	const url =
		thirdPartySelectedUserId === 0
			? "PlannerCalendarPeriod/api/v1/GetWeekInfo"
			: "PlannerCalendarPeriod/api/v1/GetThirdPartyLinkedUserWeekInfo";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
				RequestedDate: requestedDate,
			},
		});

		return response.data.result;
	} catch (error) {}
}
