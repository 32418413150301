import React, { useContext, useEffect } from "react";
import * as IoIcons from "react-icons/io";
import { Context } from "../../Context/Contexts";
import "./CartIcon.css";
import { GetCartItemsCount } from "./GetCartItemsCount";
import { GetPrompts } from "../Prompts/PromptsComponent";

export default function CartIcon() {
	const { cartItemCounter, setCartItemCounter } = useContext(Context);
	const { refreshCartItemCounter, setRefreshCartItemCounter } =
		useContext(Context);

	const refreshCounter = () => {
		GetCartItemsCount().then(function (response) {
			setCartItemCounter(response);
		});
	};

	useEffect(() => {
		refreshCounter();
	}, [0]);

	useEffect(() => {
		if (refreshCartItemCounter === true) {
			setRefreshCartItemCounter(false);
			refreshCounter();
		}
	}, [refreshCartItemCounter]);

	if (cartItemCounter <= 0) {
		return;
	}
	return (
		<>
			<div className="cart-icon-container">
				<div className="cart-icon-item-icon">
					<IoIcons.IoMdCart />
				</div>
				<div className="cart-icon-item-counter">{cartItemCounter}</div>
				<div className="cart-icon-item-text sidebar-menu-item">
					{GetPrompts("Cart")}
				</div>
			</div>
		</>
	);
}
