import customAxios from "../../../../axios";

export const DeletedMultiplePlannedActivities = async ({
	plannedActivitiesIdList,
	thirdPartySelectedUserId = 0,
}) => {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/DeletedMultiplePlannedActivities"
				: "Planner/api/v1/DeletedMultiplePlannedActivitiesForThirdPartyLinkedUser";

		const response = await customAxios.post(url, {
			UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			PlannedActivitiesIdList: plannedActivitiesIdList,
		});
	} catch (error) {}
};
