import axios from "../../../../axios";
export async function GetYearActivities({
	currentPage = 1,
	itemsPerPage = 10,
}) {
	const url = "PlannerYearActivities/api/v1/GetYearPlanActivitiesDropdowns";
	try {
		const response = await axios.get(url, {
			params: {
				CurrentPage: currentPage,
				ItemsPerPage: itemsPerPage,
			},
		});

		return response.data;
	} catch (error) {}
}

export async function GetYearActivitiesByYear({
	currentPage = 1,
	itemsPerPage = 10,
	selectedYear = 1,
	selectedChild = 0,
	thirdPartySelectedUserId = 0,
}) {
	var parseYear = parseInt(selectedYear);
	if (parseYear == 0 || isNaN(parseYear)) {
		selectedYear = 1;
	}

	const url =
		thirdPartySelectedUserId === 0
			? "PlannerYearActivities/api/v1/GetYearPlanActivitiesByYearPaged"
			: "PlannerYearActivities/api/v1/GetThirdPartyLinkedUserYearPlanActivitiesByYearPaged";
	try {
		const response = await axios.get(url, {
			params: {
				CurrentPage: currentPage,
				ItemsPerPage: itemsPerPage,
				YearId: selectedYear,
				ChildId: selectedChild,
				UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			},
		});

		return response.data;
	} catch (error) {}
}
