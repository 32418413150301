import customAxios from "../../../axios";

export const CreateNewUserLinkedToThirdParty = async ({
	name,
	lastName,
	email,
	pwd,
}) => {
	try {
		const url = `User/api/v1/CreateNewUserLinkedToThirdParty`;

		const response = await customAxios.put(url, {
			UserId: 0,
			FirstName: name,
			LastName: lastName,
			Username: email,
			Password: pwd,
		});
		return response.data;
	} catch (error) {}
};
