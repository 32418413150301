import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { LogOutLink_Home } from "../../../Components/LogOutLink/LogOutLink";
import { GetPrompts } from "../../../Components/Prompts/PromptsComponent";
import { Context } from "../../../Context/Contexts";
import { UserProfileIcon_Home } from "../../UserProfile/UserProfile";
import CalendarSettings from "../../../Components/Settings/Calendar/CalendarSettings";
import {
	CustomerSupportIcon,
	CustomerSupportIcon_Home,
} from "../../CustomerSupport/CustomerSupport";

export default function WeeklyScheduleSidebar() {
	const { selectedChildGlobal } = useContext(Context);
	const { setShowAutomatedPlanning } = useContext(Context);
	const { setHomeNavBarIsOpen, setCalendarSettingsIsOpen } =
		useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);

	const { showNewPlannedActivity, setShowNewPlannedActivity } =
		useContext(Context);

	const navigate = useNavigate();
	const GotoModule = (module) => {
		navigate(module);
	};
	return (
		<>
			<div className="home-page-sidebar-container-year-plan do-not-print">
				<div className="home-page-sidebar-upper">
					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdCalendar />
						</div>
						<div
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								GotoModule("/planner-Year");
							}}
						>
							{GetPrompts("Sidebar Planner")}
						</div>
					</div>

					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoIosCreate />
						</div>
						<div
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								setShowNewPlannedActivity(true);
							}}
						>
							{GetPrompts("Open quick planner")}
						</div>
					</div>

					{selectedChildGlobal != undefined ? (
						<div className="home-page-sidebar-item">
							<div className="home-page-sidebar-item-icon">
								<IoIcons.IoIosAnalytics />
							</div>
							<div
								className="home-page-sidebar-item-text sidebar-menu-item"
								onClick={() => {
									setShowAutomatedPlanning(true);
								}}
							>
								Planeacion automatica
							</div>
						</div>
					) : (
						<></>
					)}

					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdCalendar />
						</div>
						<div
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								GotoModule("/WeeklySchedule");
							}}
						>
							{GetPrompts("Sidebar Schedule With Time")}
						</div>
					</div>

					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdList />
						</div>
						<div
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								GotoModule("/PlanPerDayCustomReport");
							}}
						>
							{GetPrompts("Sidebar Activities Report")}
						</div>
					</div>

					{!thirdPartySelectedUserId && (
						<div className="home-page-sidebar-item">
							<div className="home-page-sidebar-item-icon">
								<IoIcons.IoMdSettings />
							</div>
							<div
								className="home-page-sidebar-item-text sidebar-menu-item"
								onClick={() => {
									setCalendarSettingsIsOpen(true);
								}}
							>
								Calendar Settings
							</div>
						</div>
					)}

					<div className="home-page-sidebar-item-text sidebar-menu-item">
						<CustomerSupportIcon_Home isOverallMenu={false} />
					</div>
				</div>

				<div className="home-page-sidebar-lower-year-plan">
					<UserProfileIcon_Home />
					<LogOutLink_Home />
				</div>
			</div>
		</>
	);
}
