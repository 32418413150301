import customAxios from "../../../axios";

export async function GetUsersByThirdPartyUserId() {
	const url = "User/api/v1/GetUsersByThirdPartyUserId";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: 0,
			},
		});
		return response.data;
	} catch (error) {}
}
