import customAxios from "../../../../axios";

export async function GetDateBasedPlanReport({
	currentPage = 1,
	itemsPerPage = 10,
	fromDate,
	toDate,
	childId = 0,
	thirdPartySelectedUserId = 0,
}) {
	const url =
		thirdPartySelectedUserId === 0
			? "PlannerReports/api/v1/GetDateBasedPlanReport"
			: "PlannerReports/api/v1/GetDateBasedPlanReportForThirdPartyLinkedUser";
	try {
		const response = await customAxios.get(url, {
			params: {
				CurrentPage: currentPage,
				ItemsPerPage: itemsPerPage,
				UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
				FromDate: fromDate,
				ToDate: toDate,
				ChildId: childId,
			},
		});
		return response.data;
	} catch (error) {}
}
