import React, { useContext, useState } from "react";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CalendarOptions_ModalStyle";
import ModalComponent from "../../../ModalComponent/ModalComponent";
import * as IoIcons from "react-icons/io";
import MoveWeek from "../../../../Pages/WeeklySchedule/MoveWeek/MoveWeek";
import { Context } from "../../../../Context/Contexts";
import { WaitForTransactionToFinish } from "../../../Transactions/WaitForTransaction";
import { RevertPlannerAction } from "../../../RevertActions/RevertPlannerAction";
import YesNoConfirm from "../../../ModalConfirm/YesNoConfirm";
import "./CalendarOptions.css";
import { DeletePlannedWeek } from "../../../DeletePlannedWeek/DeletePlannedWeek";

export default function CalendarOptions({ isOpen, close, weeklyScheduleDate }) {
	const { selectedChildGlobal } = useContext(Context);
	const [showMoveWeek, setShowMoveWeek] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationCallBack, setConfirmationCallBack] = useState();
	const { setShowIsloading, setShowIsloadingMessage } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { setRefreshWeeklySchedule } = useContext(Context);

	const RevertAction = () => {
		setShowIsloading(true);
		setShowIsloadingMessage(
			"Por favor espera, esta operacion puede tardar un poco..."
		);

		RevertPlannerAction({
			thirdPartySelectedUserId: thirdPartySelectedUserId,
		}).then(async (response) => {
			const transactionId = response.transactionId;
			await WaitForTransactionToFinish({
				transactionId: transactionId,
				retryDelay: 5000,
				retries: 24,
			}).then((response) => {
				if (response.success) {
					setRefreshWeeklySchedule(true);
					setShowDonecomponent(true);
					setShowIsloading(false);
				} else {
					//Todo: if no success after some tries say something, like we'll continue in the background
					console.log("Not done yet, we'll continue in the background...");
					setRefreshWeeklySchedule(true);
					setShowDonecomponent(true);
					setShowIsloading(false);
				}
			});
		});
	};

	const DeletePlannedWeekAction = () => {
		var calendarWeek = weeklyScheduleDate;
		setShowIsloading(true);
		setShowIsloadingMessage(
			"Por favor espera, esta operacion puede tardar un poco..."
		);

		DeletePlannedWeek({
			calendarWeek: calendarWeek,
			childId: selectedChildGlobal.planChildId,
		}).then((response) => {
			setRefreshWeeklySchedule(true);
			setShowDonecomponent(true);
			setShowIsloading(false);
		});
	};

	const CloseClickHandler = () => {
		close();
	};
	return (
		<>
			<ModalComponent
				isOpen={isOpen}
				onClose={CloseClickHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
			>
				<div className="calendar-options-container">
					<div
						className="calendar-options-close-btn"
						onClick={CloseClickHandler}
					>
						X
					</div>

					<div className="calendar-options-body">
						{!thirdPartySelectedUserId && (
							<div
								className="do-not-print hover-brighten base-button-text move-week-btn"
								onClick={() => {
									setShowMoveWeek(true);
								}}
								title="Mover semana y actividades"
							>
								<>
									<IoIcons.IoIosArrowForward /> Mover semana
								</>
							</div>
						)}
						<div
							className="do-not-print hover-brighten base-button-text revert-btn"
							onClick={(event) => {
								event.stopPropagation();

								setConfirmationCallBack(() => RevertAction);
								setConfirmationMessage(
									"Seguro que quieres revertir tu ultima accion?"
								);
								setShowConfirmation(true);
							}}
							title="Revertir/Deshacer la ultima accion"
						>
							<>
								<IoIcons.IoMdUndo /> Revertir cambios
							</>
						</div>

						{selectedChildGlobal !== undefined ? (
							<div
								className="do-not-print hover-brighten base-button-text revert-btn"
								onClick={(event) => {
									event.stopPropagation();

									setConfirmationCallBack(() => DeletePlannedWeekAction);
									setConfirmationMessage(
										"Seguro que quieres eliminar todas las actividades de la semana?"
									);
									setShowConfirmation(true);
								}}
								title="Eliminar todas las actividades de la semana"
							>
								<>
									<IoIcons.IoMdTrash /> Limpiar semana
								</>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
			</ModalComponent>

			<MoveWeek
				weeklyScheduleDate={weeklyScheduleDate}
				isOpen={showMoveWeek}
				closeHandler={() => {
					setShowMoveWeek(false);
				}}
			/>
			<YesNoConfirm
				dlgOpen={showConfirmation}
				callBackFunction={() => {
					confirmationCallBack();
					setShowConfirmation(false);
				}}
				onClose={() => {
					setConfirmationCallBack(() => {});
					setShowConfirmation(false);
				}}
				confirmMessage={confirmationMessage}
			/>
		</>
	);
}
