import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Logo.svg";
import { Context } from "../../Context/Contexts";
import { GotoBlog } from "../BlogLink/GotoBlog";
import { GotoInstagram } from "../BlogLink/GotoInstagram";
import { GetUserProfilePicture } from "../ProfilePictureUploader/GetUserProfilePicture";
import { GetPrompts } from "../Prompts/PromptsComponent";
import SidebarToggle from "../Sidebar/SidebarToggle";
import { GetUserByToken } from "../User/GetUserByToken";
import "./Navbar_Home.css";
import { Islanding } from "../CurrentPageValidations/IsLanding";
import { IsCalendar } from "../CurrentPageValidations/IsCalendar";
import { GetThirdPartyLinkedUserById } from "../ThirdPartyAdminModule/ThirdPartyUserEdition/GetThirdPartyLinkedUserById";

export default function Navbar_Home() {
	var loadCount = 0;
	const navigate = useNavigate();
	const [showSidebarToggle, setShowSidebarToggle] = useState(false);
	const { updateNavbarUserData, setUpdateNavbarUserData } = useContext(Context);
	const { thirdPartySelectedUserId, setThirdPartySelectedUserId } =
		useContext(Context);
	const { homeNavBarIsOpen } = useContext(Context);
	const { setUserProfileIsOpen } = useContext(Context);
	const { setUserIsInFreeTrial } = useContext(Context);
	const { exitApp, setExitApp } = useContext(Context);
	const { setUserIsMember } = useContext(Context);
	const { updatePictureProfile, setupdatePictureProfile } = useContext(Context);
	const [user, setUser] = useState("");
	const [base64String, setBase64String] = useState("");
	const [userData, setUserData] = useState(null);

	const GetUser = () => {
		GetUserByToken().then((response) => {
			console.log(response);
			setUser(response);
			if (response.language != undefined) {
				sessionStorage.setItem("_lang", response.language);
			}
			setUserIsMember(response.isMember);
			setUserIsInFreeTrial(response.isInFreeTrial);
		});
	};

	const FeedUserData = () => {
		GetThirdPartyLinkedUserById({ userId: thirdPartySelectedUserId }).then(
			(response) => {
				setUserData(response.result);
			}
		);
	};

	const GetProfilePicture = () => {
		GetUserProfilePicture().then((response) => {
			setBase64String(response);
		});
	};

	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		if (windowSize.width < 1400) {
			setShowSidebarToggle(true);
		} else {
			setShowSidebarToggle(false);
		}

		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [windowSize.width]);

	useEffect(() => {
		if (Islanding() == true) {
			return;
		}
		GetUser();
		GetProfilePicture();
	}, [window.location.href]);

	useEffect(() => {
		if (updateNavbarUserData === true) {
			setUpdateNavbarUserData(false);
			GetUser();
		}
	}, [updateNavbarUserData]);

	useEffect(() => {
		if (updatePictureProfile == true) {
			setupdatePictureProfile(false);
			GetProfilePicture();
		}
	}, [updatePictureProfile]);

	useEffect(() => {
		if (exitApp === true) {
			setExitApp(false);
			navigate("/landing");
		}
	}, [exitApp]);
	useEffect(() => {
		FeedUserData();
	}, [thirdPartySelectedUserId]);

	if (!homeNavBarIsOpen) {
		return;
	}
	return (
		<>
			{userData && (
				<div className="third-party-planning-mode-banner">
					<div className="third-party-planning-mode-banner-text">{`Planeando a nombre de ${userData.firstName} ${userData.lastName}`}</div>
					<div className="third-party-planning-mode-banner-btn">
						<span
							className="third-party-planning-mode-banner-btn-text"
							onClick={() => {
								setThirdPartySelectedUserId(0);
								window.location.reload();
							}}
						>
							Salir del modo Admin
						</span>
					</div>
				</div>
			)}
			<div
				id="navbar-home"
				className={`nav-bar-home-container ${
					Islanding() ? "white-background" : ""
				}`}
			>
				<div className="nav-bar-home-left nav-bar-home-left-container">
					<a
						className="nav-bar-home-brand-logo"
						onClick={() => {
							if (Islanding() == false) {
								navigate("/home");
							}
						}}
					>
						<img src={logo} className="logo" alt="logo" width={"70px"} />
					</a>
					<span className="nav-bar-home-at-hand base-sub-title">
						{GetPrompts("AT HAND")}
					</span>

					<span className="nav-bar-home-toggle">
						<SidebarToggle />
					</span>
				</div>

				{!Islanding() ? (
					<>
						{!IsCalendar() ? (
							<div className="nav-bar-home-middle do-not-print">
								<>
									<div className="nav-bar-home-middle-left">
										<div
											className="nav-bar-home-middle-left-item base-sub-title hover-effect"
											onClick={() => {
												navigate("/home");
											}}
										>
											{GetPrompts("Home Sidebar Home")}
										</div>
										<div
											className="nav-bar-home-middle-left-item base-sub-title hover-effect"
											onClick={() => {
												GotoBlog();
											}}
										>
											{GetPrompts("About Us")}
										</div>
										<div
											className="nav-bar-home-middle-left-item base-sub-title hover-effect"
											onClick={() => {
												GotoInstagram();
											}}
										>
											<FontAwesomeIcon icon={faInstagram} />
											<span className="nav-bar-home-instagram-text base-sub-title ">
												{GetPrompts("Follow us")}
											</span>
										</div>

										{user.isInFreeTrial && (
											<div
												className="nav-bar-home-user-free hover-brighten"
												title="4 meses gratis"
											>
												<span>FREE TRIAL</span>
											</div>
										)}
									</div>
								</>
							</div>
						) : (
							""
						)}
						<div className="nav-bar-home-right do-not-print">
							<div className="nav-bar-home-user">
								<div className="nav-bar-home-user-picture">
									{user
										? base64String && (
												<a>
													<img
														src={`data:image/png;base64,${base64String}`}
														className="profile-picture-uploader-image-profile"
														alt="profile"
														width={"40px"}
													/>
												</a>
										  )
										: ""}
								</div>
								<div className="nav-bar-home-user-name base-sub-title">
									{user ? `${user.firstName} ${user.lastName}` : ""}
								</div>
								<div
									id="navbar-user-profile"
									className="nav-bar-home-user-profile base-parafraph"
									onClick={() => {
										setUserProfileIsOpen(true);
									}}
								>
									<span>{GetPrompts("Sidebar My Profile")}</span>
								</div>
							</div>
						</div>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
}
