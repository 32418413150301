import customAxios from "../../../../axios";

export async function GetPeriodForOneWeek({
	requestedDate,
	thirdPartySelectedUserId = 0,
}) {
	const url =
		thirdPartySelectedUserId === 0
			? "PlannerCalendarPeriod/api/v1/GetPeriodForOneWeek"
			: "PlannerCalendarPeriod/api/v1/GetThirdPartyLinkedUserPeriodForOneWeek";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
				RequestedDate: requestedDate,
			},
		});

		return response.data;
	} catch (error) {}
}
