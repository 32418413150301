import React, { useContext, useEffect } from "react";
import * as IoIcons from "react-icons/io";
import "./MarkActivityAsCompletedIcon.css";
import { Context } from "../../../../Context/Contexts";
import { MarkPlannedActivityAsNotCompleted } from "./MarkPlannedActivityAsNotCompleted";
import { MarkPlannedActivityAsCompleted } from "./MarkPlannedActivityAsCompleted";

export default function MarkActivityAsCompletedIcon({
	activity,
	show = false,
}) {
	const { setRefreshWeeklySchedule } = useContext(Context);
	const clickHandler = () => {
		if (activity.isCompleted === true) {
			MarkPlannedActivityAsNotCompleted({ plannerId: activity.plannerId }).then(
				(response) => {
					setRefreshWeeklySchedule(true);
				}
			);
		} else {
			MarkPlannedActivityAsCompleted({ plannerId: activity.plannerId }).then(
				(response) => {
					setRefreshWeeklySchedule(true);
				}
			);
		}
	};

	return (
		<>
			<div
				className="mark-activity-as-completed-icon-container"
				title={`${
					activity.isCompleted
						? "Actividad completada"
						: "Marcar como completada"
				}`}
				onClick={clickHandler}
			>
				{activity.isCompleted === true ? (
					<div className={`mark-activity-as-completed-icon-completed`}>
						<IoIcons.IoMdCheckmark />
					</div>
				) : show === true ? (
					<div className={`mark-activity-as-completed-icon-none`}>
						<IoIcons.IoMdCheckmark />
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
}
