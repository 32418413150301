import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context/Contexts";
import { GetThirdPartyLinkedUserById } from "./GetThirdPartyLinkedUserById";
import "./ThirdPartyUserEdition.css";
import { EditUserProfileByThirdParty } from "./EditUserProfileByThirdParty";

export default function ThirdPartyUserEdition() {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);
	const { setRefreshThirdPartyAdminModule } = useContext(Context);
	const [userData, setUserData] = useState(null);
	const [isEditing, setIsEditing] = useState(false);

	const FeedUserData = () => {
		setIsEditing(false);
		setShowIsloading(true);
		GetThirdPartyLinkedUserById({ userId: thirdPartySelectedUserId }).then(
			(response) => {
				setShowIsloading(false);
				setUserData(response.result);
			}
		);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUserData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSave = () => {
		setShowIsloading(true);
		const firstName = userData.firstName;
		const lastName = userData.lastName;

		EditUserProfileByThirdParty({
			userId: thirdPartySelectedUserId,
			firstName: firstName,
			lastName: lastName,
		}).then((response) => {
			setShowIsloading(false);
			setShowDonecomponent(true);
			setRefreshThirdPartyAdminModule(true);
		});

		setIsEditing(false);
	};

	useEffect(() => {
		FeedUserData();
	}, [thirdPartySelectedUserId]);

	if (!userData) {
		return;
	}

	return (
		<>
			<div className="third-party-user-edition-container">
				<form className="third-party-create-user-form">
					<div>
						<label htmlFor="username">Email:</label>
						<input
							title={
								isEditing && "Solo el mismo usuario puede editar su correo"
							}
							type="text"
							id="username"
							name="username"
							value={userData.username || ""}
							onChange={handleInputChange}
							className="third-party-create-user-form-input"
							disabled={true}
						/>
					</div>
					<div>
						<label htmlFor="firstName">First Name:</label>
						<input
							type="text"
							id="firstName"
							name="firstName"
							value={userData.firstName || ""}
							onChange={handleInputChange}
							className="third-party-create-user-form-input"
							disabled={!isEditing}
						/>
					</div>
					<div>
						<label htmlFor="lastName">Last Name:</label>
						<input
							type="text"
							id="lastName"
							name="lastName"
							value={userData.lastName || ""}
							onChange={handleInputChange}
							className="third-party-create-user-form-input"
							disabled={!isEditing}
						/>
					</div>
					<div>
						{isEditing ? (
							<>
								<div className="third-party-create-user-form-save-btn-container">
									<button
										type="button"
										onClick={handleSave}
										className="third-party-create-user-form-save-btn hover-brighten"
									>
										Save
									</button>
									<button
										type="button"
										onClick={() => setIsEditing(false)}
										className="third-party-create-user-form-cancel-btn hover-brighten"
									>
										Cancel
									</button>
								</div>
							</>
						) : (
							<>
								<div className="third-party-create-user-form-save-btn-container">
									<button
										type="button"
										onClick={() => setIsEditing(true)}
										className="third-party-create-user-form-save-btn hover-brighten"
									>
										Edit
									</button>
								</div>
							</>
						)}
					</div>
				</form>
			</div>
		</>
	);
}
