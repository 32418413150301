export const MODAL_STYLES = {
	borderRadius: "10px",
	position: "fixed",
	width: "420px",
	height: "Auto",
	maxHeight: "650px",
	paddingBottom: "10px",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#FFF",
	zIndex: 1000,
};

export const OVERLAY_STYLES = {
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(0, 0, 0, .7)",
	zIndex: 1000,
};
