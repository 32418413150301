import React, { useContext, useEffect, useState } from "react";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./ThirdPartyAdminApplyPlan_ModalStyle";
import "./ThirdPartyAdminApplyPlan.css";
import { GetThirdPartyLinkedUserChildren } from "./GetThirdPartyLinkedUserChildren";
import { GetThirdPartyUserChildren } from "./GetThirdPartyUserChildren";
import ModalComponent from "../../ModalComponent/ModalComponent";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import DatePickerPopUp from "../../DatePicker/DatePickerPopUp";
import moment from "moment";
import { Context } from "../../../Context/Contexts";
import { ApplyAdminPlanToThirdPartyLinkedUser } from "./ApplyAdminPlanToThirdPartyLinkedUser";

export default function ThirdPartyAdminApplyPlan({ isOpen, close }) {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent, setShowIsloadingMessage } = useContext(Context);

	const { thirdPartySelectedUserId, setThirdPartySelectedUserId } =
		useContext(Context);
	const [targetStudentList, setTargetStudentList] = useState([]);
	const [sourceStudentList, setSourceStudentList] = useState([]);
	const [selectedTargetStudent, setSelectedTargetStudent] = useState("");
	const [selectedSourceStudent, setSelectedSourceStudent] = useState("");
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [startFromDate, setStartFromDate] = useState(new Date());

	const CloseClickHandler = () => {
		close();
	};

	const handleTargetChange = (event) => {
		setSelectedTargetStudent(event.target.value);
	};

	const handleSourceChange = (event) => {
		setSelectedSourceStudent(event.target.value);
	};
	const DateOfBirthEditionHandler = (e) => {
		setStartFromDate(moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};
	const ApplyClickHandler = () => {
		setShowIsloading(true);
		setShowIsloadingMessage(
			"Por favor espera, esta operacion puede tardar un poco..."
		);
		ApplyAdminPlanToThirdPartyLinkedUser({
			userId: thirdPartySelectedUserId,
			sourceChildId: selectedSourceStudent,
			targetChildId: selectedTargetStudent,
			startFromDate: startFromDate,
		}).then((response) => {
			setShowDonecomponent(true);
			setShowIsloading(false);
			console.log(response);
		});
	};

	useEffect(() => {
		if (isOpen === true && thirdPartySelectedUserId > 0) {
			GetThirdPartyLinkedUserChildren({
				userId: thirdPartySelectedUserId,
			}).then((response) => {
				console.log(response);
				setTargetStudentList(response.result);
			});
			GetThirdPartyUserChildren().then((response) => {
				console.log(response);
				setSourceStudentList(response.result);
			});
		}
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<>
			<ModalComponent
				isOpen={isOpen}
				onClose={CloseClickHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
				showBackgroundImage={false}
			>
				<div className="third-party-admin-apply-plan-container">
					<div
						className="third-party-admin-apply-plan-close-btn"
						onClick={CloseClickHandler}
					>
						X
					</div>
					<div className="third-party-admin-apply-plan-body">
						<div className="third-party-admin-apply-plan-target-students">
							<FormControl fullWidth>
								<InputLabel id="target-student-label">
									Estudiante que recibe el plan
								</InputLabel>
								<Select
									labelId="target-student-label"
									value={selectedTargetStudent}
									onChange={(e) => setSelectedTargetStudent(e.target.value)}
								>
									{targetStudentList.map((student) => (
										<MenuItem
											key={student.planChildId}
											value={student.planChildId}
										>
											{student.firstName} {student.lastName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="third-party-admin-apply-plan-source-students">
							<FormControl fullWidth>
								<InputLabel id="target-student-label">
									Estudiante fuente del plan
								</InputLabel>
								<Select
									labelId="target-student-label"
									value={selectedSourceStudent}
									onChange={(e) => setSelectedSourceStudent(e.target.value)}
								>
									{sourceStudentList.map((student) => (
										<MenuItem
											key={student.planChildId}
											value={student.planChildId}
										>
											{student.firstName} {student.lastName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</div>

					<div
						className="third-party-admin-apply-plan-start-from-date"
						onClick={() => {
							setShowDatePicker(true);
						}}
					>
						<InputLabel>Aplicar plan desde:</InputLabel>
						<div>
							<div className="third-party-admin-apply-plan-start-from-date-text">
								{startFromDate != undefined
									? moment(startFromDate).format("DD/MM/YYYY")
									: moment(new Date()).format("DD/MM/YYYY")}
							</div>
						</div>
					</div>
					<div className="third-party-admin-apply-plan-btn-container">
						<div onClick={CloseClickHandler}>Cancel</div>
						<div
							onClick={() => {
								ApplyClickHandler();
							}}
						>
							Apply
						</div>
					</div>
				</div>
			</ModalComponent>

			<DatePickerPopUp
				open={showDatePicker}
				close={() => {
					setShowDatePicker(false);
				}}
				onChange={DateOfBirthEditionHandler}
			/>
		</>
	);
}
