import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import YesNoConfirm from "../ModalConfirm/YesNoConfirm";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "../ModalConfirm/Yesno_ModalCenteredStyle";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./CartItemCard.css";
import { DeleteCartItem } from "./DeleteCartItem";
import { GetPrompts } from "../Prompts/PromptsComponent";

export default function CartItemCard({
	cartItem,
	UpdateCartPage,
	yesNoDlgOpen,
	setYesNoDlgOpen,
}) {
	const { qty, price, itemId, userId, details } = cartItem;
	const { productCode, description, picturePath } = cartItem.product;

	const [isExpanded, setIsExpanded] = useState(false);

	const DeleteItem = () => {
		setYesNoDlgOpen(false);
		DeleteCartItem({ cartItemId: itemId, userId: userId }).then((response) => {
			UpdateCartPage();
		});
	};

	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};
	const maxLengthToDisplay = 100;
	const truncatedText = isExpanded
		? GetPrompts(description)
		: GetPrompts(description).slice(0, maxLengthToDisplay);

	return (
		<>
			<div
				id={`cart-item-id-key${itemId}`}
				key={`cartItemId${itemId}`}
				className="cart-item-card-container-outter"
			>
				<div className="cart-item-card-container">
					<div className="cart-item-card-product-picture">
						<img src={picturePath} alt="" width={"100px"} />
					</div>
					<div className="cart-item-card-body">
						<div className="base-sub-title">Cantidad: {qty}</div>
						<div className="base-sub-title">Precio: ${price}</div>
						<div className="cart-item-card-description base-parafraph">
							{truncatedText}
							{GetPrompts(description).length > maxLengthToDisplay && (
								<div className="show-more-btn" onClick={toggleText}>
									...
									{isExpanded
										? GetPrompts("Show less")
										: GetPrompts("Show more")}
								</div>
							)}
						</div>
						<div className="cart-item-card-description base-parafraph">
							{details}
						</div>
					</div>
					<div className="cart-item-card-footer">
						<span className="cart-item-card-footer-delete-btn">
							<FontAwesomeIcon
								icon={faTrash}
								className="cart-item-card-delete-btn"
								onClick={() => setYesNoDlgOpen(true)}
							/>
						</span>
					</div>
				</div>
			</div>

			<YesNoConfirm
				dlgOpen={yesNoDlgOpen}
				callBackFunction={DeleteItem}
				onClose={() => setYesNoDlgOpen(false)}
				confirmMessage={"Seguro que quieres borrar este item del carrito?"}
			/>
		</>
	);
}
