import {
	faCancel,
	faEdit,
	faPersonCircleMinus,
	faPersonCirclePlus,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import "./EditPlannedActivity.css";
import {
	MODAL_STYLES_EDIT,
	OVERLAY_STYLES,
} from "./EditPlannedActivity_ModalStyle";

import DateTimePicker from "react-datetime-picker";
import ReactTooltip from "react-tooltip";
import { Context } from "../../../Context/Contexts";
import ChildrenSelector_Custom from "../../Children/Selectors/ChildrenSelector_Custom";
import DatePickerPopUp from "../../DatePicker/DatePickerPopUp";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import { DeletePlannedActivityPopUp } from "../DeletePlannedActivityPopUp/DeletePlannedActivityPopUp";
import { EditExistingPlannedActivityByDateRangeByChild } from "./EditExistingPlannedActivityByDateRangeByChild";
import { MarkPlannedActivityAsNotPending } from "../WeeklySchedule/MarkActivityAsPending/MarkPlannedActivityAsNotPending";
import { MarkPlannedActivityAsPending } from "../WeeklySchedule/MarkActivityAsPending/MarkPlannedActivityAsPending";
import YearSelector from "../YearPlan/YearSelector/YearSelector";
import { UpdateActivityYear } from "./UpdateActivityYear";
import { YearActivityDropdowns } from "../../GetDropdowns/YearActivityDropdowns";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
	quillEditorExtractTextFromHtml,
	quillEditorFormats,
	quillEditorModules,
	quillEditorSimpleModules,
} from "../../RichTextEditor/RichTextEditor";
import { blue } from "@mui/material/colors";
import { SafeHtmlComponent } from "../../RichTextEditor/SafeHtmlComponent";
import DuplicateActivityModal from "../../DuplicateActivity/DuplicateActivityModal";

export default function EditPlannedActivity({
	open,
	plannedActivity,
	onClose,
	GetPlannedActivities,
	callback = undefined,
}) {
	const { setRefreshWeeklySchedule } = useContext(Context);
	var loadCount = 0;
	const lang = sessionStorage.getItem("_lang");
	const { setShowIsloading } = useContext(Context);
	const { showToolTip, setShowToolTip } = useContext(Context);
	const { yearPlanDropdowns, setYearPlanDropdowns } = useContext(Context);
	const { thirdPartySelectedUserId } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const [deleteIsOpen, setDeleteIsOpen] = useState(false);
	const [showDateRangeEditon, setShowDateRangeEditon] = useState(false);
	const [isDateRangeEdition, setIsDateRangeEdition] = useState(false);
	const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
	const [childrenList, setChildrenList] = useState([]);
	const [showDuplicateActivityModal, setShowDuplicateActivityModal] =
		useState(false);

	const [selectedChildrenToAdd, setSelectedChildrenToAdd] = useState([]);
	const [selectedChildrenToRemove, setSelectedChildrenToRemove] = useState([]);
	const [selectedYear, setSelectedYear] = useState();
	const [showYearSelector, setShowYearSelector] = useState(false);
	const [activityInEdition, setActivityInEdition] = useState(false);
	const [commentsInEdition, setCommentsInEdition] = useState(false);

	const [editionFromDatePicker, setEditionFromDatePicker] = useState(
		new Date()
	);
	const [editionToDatePicker, setEditionToDatePicker] = useState(new Date());
	const [editionComment, setEditionComment] = useState("");
	const [showFromDateDateTimePicker, setShowFromDateDateTimePicker] =
		useState(false);
	const [showToDateDateTimePicker, setShowToDateDateTimePicker] =
		useState(false);

	const [showActivityDateDateTimePicker, setShowActivityDateDateTimePicker] =
		useState(false);

	const [
		editionDateActivityDateTimePicker,
		setEditionDateActivityDateTimePicker,
	] = useState();

	const [editionFromTimePicker, setEditionFromTimePicker] = useState(
		new Date()
	);
	const [editionToTimePicker, setEditionToTimePicker] = useState(new Date());

	const CommentsEditionHandler = () => {
		setCommentsInEdition(!commentsInEdition);
		setActivityInEdition(true);
	};

	const ResetVariables = () => {
		setIsDateRangeEdition(false);
		setSelectedChildrenToAdd([]);
		setSelectedChildrenToRemove([]);
		setEditionComment("");
	};
	const EditionFromDateHandler = (e) => {
		setActivityInEdition(true);
		setIsDateRangeEdition(true);
		setEditionFromDatePicker(e);
	};
	const EditionToDateHandler = (e) => {
		setActivityInEdition(true);
		setIsDateRangeEdition(true);
		setEditionToDatePicker(e);
	};

	const EditionFromTimeDateHandler = (e) => {
		setActivityInEdition(true);
		setEditionFromTimePicker(e);
	};
	const EditionDateActivityDateTimeDateHandler = (e) => {
		setActivityInEdition(true);
		setEditionDateActivityDateTimePicker(e);
	};

	const EditionToTimeDateHandler = (e) => {
		setActivityInEdition(true);
		setEditionToTimePicker(e);
	};

	const CloseClickHandler = () => {
		setActivityInEdition(false);
		onClose();
	};

	const ChildAddChangeHandler = async (e) => {
		setActivityInEdition(true);
		setSelectedChildrenToAdd(e);
	};
	const ChildRemoveChangeHandler = async (e) => {
		setActivityInEdition(true);
		setSelectedChildrenToRemove(e);
	};

	const FeedEditionVariables = async (e) => {
		setEditionFromTimePicker(new Date(plannedActivity.fromTime));
		setEditionToTimePicker(new Date(plannedActivity.toTime));
	};

	const GetDropdowns = () => {
		YearActivityDropdowns(yearPlanDropdowns, setYearPlanDropdowns).then(
			function (result) {
				setChildrenList(result.children);
			}
		);
	};

	const UpdateActivityYearHandler = (yearId) => {
		UpdateActivityYear({
			plannerId: plannedActivity.plannerId,
			yearId: selectedYear.yearId,
		}).then((response) => {
			setRefreshWeeklySchedule(true);
		});
	};
	const SaveBtnHandler = () => {
		var plannerId = plannedActivity.plannerId;
		var noFormatComment = quillEditorExtractTextFromHtml(editionComment);

		setShowIsloading(true);
		EditExistingPlannedActivityByDateRangeByChild({
			plannerId: plannerId,
			fromDate: editionFromDatePicker,
			toDate: editionToDatePicker,
			fromHour: editionFromTimePicker,
			fromMinute: editionFromTimePicker,
			toHour: editionToTimePicker,
			toMinute: editionToTimePicker,
			comments: noFormatComment,
			formattedComments: editionComment,
			childrenToAddList: selectedChildrenToAdd,
			childrenToRemoveList: selectedChildrenToRemove,
			editDateRange: isDateRangeEdition,
			activityDateChange: editionDateActivityDateTimePicker,
			thirdPartySelectedUserId: thirdPartySelectedUserId,
		}).then((response) => {
			ResetVariables();
			setShowDonecomponent(true);
			setShowIsloading(false);
			setRefreshWeeklySchedule(true);
			if (callback !== undefined) {
				callback();
			}
			onClose();
		});
	};

	const MarkAsPending = () => {
		var plannerId = plannedActivity.plannerId;
		MarkPlannedActivityAsPending({ plannerId }).then((response) => {
			setRefreshWeeklySchedule(true);
			onClose();
		});
	};
	const MarkAsNotPending = () => {
		var plannerId = plannedActivity.plannerId;
		MarkPlannedActivityAsNotPending({ plannerId }).then((response) => {
			setRefreshWeeklySchedule(true);
			onClose();
		});
	};

	useEffect(() => {
		if (open) {
			setEditionDateActivityDateTimePicker(new Date(plannedActivity.fromTime));
			setEditionFromDatePicker(new Date());
			setEditionToDatePicker(new Date());
			setShowDateRangeEditon(false);
			setActivityInEdition(false);
			FeedEditionVariables();
			setSelectedYear();
		}
	}, [plannedActivity]);

	useEffect(() => {
		if (activityInEdition == true) {
			setSaveBtnEnabled(true);
		} else {
			setCommentsInEdition(false);
			setSaveBtnEnabled(false);
		}
	}, [activityInEdition]);

	useEffect(() => {
		if (selectedYear != undefined) {
			UpdateActivityYearHandler();
		}
	}, [selectedYear]);

	useEffect(() => {
		if (open) {
			ResetVariables();
		}
	}, [open]);

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetDropdowns();
		loadCount = loadCount + 1;
	};

	if (!open) return null;

	var pendingFlagClassName = "edit-planned-activity-pending";
	var notPendingFlagClassName = "edit-planned-activity-not-pending";
	return (
		<>
			<div
				style={OVERLAY_STYLES}
				onClick={() => {
					onClose();
				}}
			></div>

			<div
				className="edit-planned-activity-card"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES_EDIT)}
			>
				<>
					<div
						className="edit-planned-activity-close-btn"
						onClick={CloseClickHandler}
					>
						X
					</div>

					<div className="edit-planned-activity-title base-title-normal-height">
						{" "}
						{plannedActivity.activity.description}
						{plannedActivity.isPending ? (
							<div data-tip data-for={pendingFlagClassName}>
								{showToolTip ? (
									<ReactTooltip id={pendingFlagClassName}>
										{GetPrompts("Remove Pending Mark Text")}
									</ReactTooltip>
								) : (
									""
								)}

								<IoIcons.IoMdFlag
									className={pendingFlagClassName}
									onMouseEnter={() => setShowToolTip(true)}
									onMouseLeave={() => setShowToolTip(false)}
									onClick={() => {
										MarkAsNotPending();
									}}
								/>
							</div>
						) : (
							<div>
								<div data-tip data-for={notPendingFlagClassName}>
									{showToolTip ? (
										<ReactTooltip id={notPendingFlagClassName}>
											{GetPrompts("Mark As Pending Text")}
										</ReactTooltip>
									) : (
										""
									)}

									<IoIcons.IoMdFlag
										className={notPendingFlagClassName}
										onMouseEnter={() => setShowToolTip(true)}
										onMouseLeave={() => setShowToolTip(false)}
										onClick={() => {
											MarkAsPending();
										}}
									/>
								</div>
							</div>
						)}
						<div
							className="edit-planned-activity-year base-sub-title"
							onClick={() => {
								setShowYearSelector(true);
								setActivityInEdition(true);
							}}
						>
							{selectedYear != undefined
								? selectedYear[`description${lang}`]
								: `${plannedActivity.planYear[`description${lang}`]}`}
						</div>
					</div>

					{!commentsInEdition ? (
						<div className="edit-planned-activity-comment base-parafraph">
							<div
								className="edit-planned-activity-comment-edit-btn"
								onClick={() => CommentsEditionHandler()}
							>
								<div className="edit-planned-activity-comment-edit-btn-container">
									{plannedActivity.comments !== "" ? (
										<>
											<span className="edit-planned-activity-comment-edit-btn-text">
												{plannedActivity.formattedComments !== null ? (
													<SafeHtmlComponent
														htmlContent={plannedActivity.formattedComments}
													/>
												) : (
													plannedActivity.comments
												)}
											</span>
										</>
									) : (
										"Click para agregar comentario"
									)}
								</div>
							</div>
						</div>
					) : (
						<div className="edit-planned-activity-comment">
							<ReactQuill
								className="edit-planned-activity-comment-input base-parafraph borderless-quill"
								value={
									editionComment === ""
										? plannedActivity.formattedComments !== null
											? plannedActivity.formattedComments
											: plannedActivity.comments
										: editionComment
								}
								onChange={setEditionComment}
								placeholder={plannedActivity.formattedComments}
								theme="snow"
								modules={quillEditorSimpleModules}
								formats={quillEditorFormats}
							/>
							<p>
								<FontAwesomeIcon
									icon={faCancel}
									className="edit-planned-activity-comment-edit-cancel-btn"
									onClick={() => CommentsEditionHandler()}
								/>
							</p>
						</div>
					)}
					<br />
					<div className="edit-planned-activity-child base-sub-title">
						{plannedActivity.planerChild.firstName}
					</div>

					{!showDateRangeEditon ? (
						<>
							<div className="edit-planned-activity-edit-dates-container">
								<div className="edit-planned-card-label edit-planned-activity-date-label base-sub-title">
									{GetPrompts("Date Text")}:
								</div>
								<div
									className="edit-planned-activity-date-input"
									onClick={() => {
										setShowActivityDateDateTimePicker(true);
									}}
								>
									<div className="child-edition-form-input-text">
										{Moment(editionDateActivityDateTimePicker).format(
											"DD/MM/YYYY"
										)}
									</div>
								</div>
							</div>
						</>
					) : (
						""
					)}

					{plannedActivity.activityType !== "Order" ? (
						<div className="edit-planned-activity-time-option-container">
							<div className="edit-planned-activity-edit-dates-container">
								<div className="edit-planned-card-label edit-planned-activity-from-time-label">
									{GetPrompts("Starts Text")}:
								</div>
								<div className="edit-planned-activity-from-time">
									<DateTimePicker
										className="edit-planned-activity-from-time"
										onChange={(e) => EditionFromTimeDateHandler(e)}
										format={"hh:mm a"}
										value={editionFromTimePicker}
										calendarIcon={null}
										clearIcon={null}
										disableClock={true}
									/>
								</div>
							</div>

							<div className="edit-planned-activity-edit-dates-container">
								<div className="edit-planned-card-label edit-planned-activity-to-time-label">
									{GetPrompts("Ends Text")}:
								</div>
								<div className="edit-planned-activity-to-time">
									<DateTimePicker
										className="edit-planned-activity-to-time"
										onChange={(e) => EditionToTimeDateHandler(e)}
										format={"hh:mm a"}
										value={editionToTimePicker}
										calendarIcon={null}
										clearIcon={null}
										disableClock={true}
									/>
								</div>
							</div>
						</div>
					) : (
						<></>
					)}

					<div
						className="edit-date-range-option"
						onClick={() => {
							setShowDateRangeEditon(!showDateRangeEditon);
						}}
					>
						<IoIcons.IoMdCalendar className="edit-date-range-option-icon" />{" "}
						<span className="edit-date-range-option-text">
							{GetPrompts("Edit in various days Text")}
						</span>
						<span className="edit-in-various-days-optional">
							{" "}
							- {GetPrompts("Optional")}
						</span>
					</div>

					{showDateRangeEditon ? (
						<>
							<div className="edit-planned-activity-edit-dates-container">
								<div className="edit-planned-card-label">
									{GetPrompts("Edit From Text")}:
								</div>

								<div
									className="edit-planned-activity-date-input"
									onClick={() => {
										setShowFromDateDateTimePicker(true);
									}}
								>
									<div className="child-edition-form-input-text">
										{Moment(editionFromDatePicker).format("DD/MM/YYYY")}
									</div>
								</div>
							</div>

							<div className="edit-planned-activity-edit-dates-container">
								<div className="edit-planned-card-label ">
									{GetPrompts("Edit To Text")}:
								</div>

								<div
									className="edit-planned-activity-date-input"
									onClick={() => {
										setShowToDateDateTimePicker(true);
									}}
								>
									<div className="child-edition-form-input-text">
										{Moment(editionToDatePicker).format("DD/MM/YYYY")}
									</div>
								</div>
							</div>
						</>
					) : (
						""
					)}
				</>

				<div className="edit-planned-activity-footer-btn-container">
					<FontAwesomeIcon
						icon={faTrash}
						className="edit-planned-activity-delete-btn"
						onClick={() => setDeleteIsOpen(true)}
					/>
					<div
						onClick={(event) => {
							setShowDuplicateActivityModal(true);
						}}
						className="edit-planned-activity-duplicate"
						title={`Duplicar actividad`}
					>
						<IoIcons.IoIosCopy />
					</div>
					<div className="edit-planned-activity-add-child-icon">
						<ChildrenSelector_Custom
							tooltipMsg={GetPrompts("Add Student Text")}
							iconClasssname={"edit-planned-activity-add-child-btn"}
							iconToShow={faPersonCirclePlus}
							setSelectedChildren={ChildAddChangeHandler}
							multiselect={true}
							currentChildId={plannedActivity.planerChild.planChildId}
						/>
					</div>

					{saveBtnEnabled ? (
						<div
							className="edit-planned-activity-save-btn hover-brighten"
							onClick={() => {
								SaveBtnHandler();
							}}
						>
							{GetPrompts("Save text")}
						</div>
					) : (
						""
					)}
				</div>
			</div>

			<DeletePlannedActivityPopUp
				open={deleteIsOpen}
				plannedActivity={plannedActivity}
				close={() => setDeleteIsOpen(false)}
				closeMainCaller={() => {
					setDeleteIsOpen(false);
					onClose();
					setRefreshWeeklySchedule(true);
				}}
			/>
			<DuplicateActivityModal
				isOpen={showDuplicateActivityModal}
				close={() => {
					setShowDuplicateActivityModal(false);
				}}
				plannedActivityId={plannedActivity.plannerId}
			/>

			<DatePickerPopUp
				open={showFromDateDateTimePicker}
				close={() => {
					setShowFromDateDateTimePicker(false);
				}}
				onChange={EditionFromDateHandler}
			/>

			<DatePickerPopUp
				open={showToDateDateTimePicker}
				close={() => {
					setShowToDateDateTimePicker(false);
				}}
				onChange={EditionToDateHandler}
			/>

			<DatePickerPopUp
				open={showActivityDateDateTimePicker}
				close={() => {
					setShowActivityDateDateTimePicker(false);
				}}
				onChange={EditionDateActivityDateTimeDateHandler}
			/>

			<YearSelector
				isopen={showYearSelector}
				close={() => setShowYearSelector(false)}
				selectedYear={selectedYear != undefined ? selectedYear : ""}
				setSelectedYear={(e) => {
					setSelectedYear(e);
				}}
			/>
		</>
	);
}
