import customAxios from "../../../axios";

export const EditUserProfileByThirdParty = async ({
	userId,
	firstName,
	lastName,
}) => {
	try {
		const url = `User/api/v1/EditUserProfileByThirdParty`;

		const response = await customAxios.put(url, {
			UserId: userId,
			FirstName: firstName,
			LastName: lastName,
		});
	} catch (error) {}
};
