import customAxios from "../../../axios";

export const DuplicateWeekToAnotherWeek = async ({
	calendarWeek,
	destinationCalendarWeek,
	deleteExistingActivities,
	childId,
	thirdPartySelectedUserId = 0,
}) => {
	try {
		const url =
			thirdPartySelectedUserId === 0
				? "Planner/api/v1/DuplicateWeekToAnotherWeek"
				: "Planner/api/v1/DuplicateWeekToAnotherWeekForThirdPartyLinkedUser";

		const response = await customAxios.post(url, {
			UserId: thirdPartySelectedUserId === 0 ? 0 : thirdPartySelectedUserId,
			CalendarWeek: calendarWeek,
			DestinationCalendarWeek: destinationCalendarWeek,
			DeleteExistingActivities: deleteExistingActivities,
			ChildId: childId,
		});
		return response.data;
	} catch (error) {}
};
