import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import { UserProfileIcon_Home } from "../../Pages/UserProfile/UserProfile";
import { Navigate, useNavigate } from "react-router-dom";
import { GetPrompts } from "../Prompts/PromptsComponent";
import { LogOutLink_Home } from "../LogOutLink/LogOutLink";
import { Context } from "../../Context/Contexts";
import CartIcon from "../Cart/CartIcon";

export default function ThirdPartyAdminSidebar() {
	const { setShowThirdPartyCreateUser } = useContext(Context);

	const navigate = useNavigate();
	const GotoModule = (module) => {
		navigate(module);
	};
	return (
		<>
			<div className="third-party-admin-sidebar-container">
				<div className="home-page-sidebar-container-year-plan">
					<div className="home-page-sidebar-upper">
						<div className="home-page-sidebar-item">
							<div className="home-page-sidebar-item-icon">
								<IoIcons.IoMdPersonAdd />
							</div>
							<div
								id="year-planner-sidebar-weekly-calendar"
								className="home-page-sidebar-item-text sidebar-menu-item"
								onClick={() => {
									setShowThirdPartyCreateUser(true);
								}}
							>
								Crear usuario
							</div>
							<div
								className="home-page-sidebar-item"
								onClick={() => {
									GotoModule("/Cart");
								}}
							>
								<CartIcon />
							</div>
						</div>
						<div className="home-page-sidebar-item">
							<div className="home-page-sidebar-item-icon">
								<IoIcons.IoMdCalendar />
							</div>
							<div
								id="year-planner-sidebar-weekly-calendar"
								className="home-page-sidebar-item-text sidebar-menu-item"
								onClick={() => {
									GotoModule("/WeeklyScheduleOrdered");
								}}
							>
								{GetPrompts("Sidebar Schedule")}
							</div>
						</div>
					</div>

					<div className="home-page-sidebar-lower-year-plan">
						<UserProfileIcon_Home />
						<LogOutLink_Home />
					</div>
				</div>
			</div>
		</>
	);
}
