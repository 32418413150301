import moment from "moment";

export default function groupData(data, groupBy) {
	if (groupBy === "day") {
		return data;
	} else if (groupBy === "pending") {
		console.log(data);
		const groupedByPending = {};
		data.forEach((day) => {
			day.activityList.forEach((activity) => {
				if (activity.isPending) {
					if (!groupedByPending[activity.activity.description]) {
						groupedByPending[activity.activity.description] = [];
					}
					groupedByPending[activity.activity.description].push({
						...activity,
						date: day.date,
					});
				}
			});
		});
		return Object.entries(groupedByPending).map(([key, value]) => ({
			activity: key,
			activityList: value,
		}));
	} else if (groupBy === "completed") {
		console.log(data);
		const groupedByPending = {};
		data.forEach((day) => {
			day.activityList.forEach((activity) => {
				if (activity.isCompleted) {
					if (!groupedByPending[activity.activity.description]) {
						groupedByPending[activity.activity.description] = [];
					}
					groupedByPending[activity.activity.description].push({
						...activity,
						date: day.date,
					});
				}
			});
		});
		return Object.entries(groupedByPending).map(([key, value]) => ({
			activity: key,
			activityList: value,
		}));
	} else if (groupBy === "activity") {
		const groupedByActivity = {};
		data.forEach((day) => {
			day.activityList.forEach((activity) => {
				if (!groupedByActivity[activity.activity.description]) {
					groupedByActivity[activity.activity.description] = [];
				}
				groupedByActivity[activity.activity.description].push({
					...activity,
					date: day.date,
				});
			});
		});
		return Object.entries(groupedByActivity).map(([key, value]) => ({
			activity: key,
			activityList: value,
		}));
	} else if (groupBy === "student") {
		const groupedByStudent = {};
		data.forEach((day) => {
			day.activityList.forEach((activity) => {
				activity.children.forEach((child) => {
					if (!groupedByStudent[child.firstName]) {
						groupedByStudent[child.firstName] = [];
					}
					groupedByStudent[child.firstName].push({
						...activity,
						date: day.date,
					});
				});
			});
		});
		return Object.entries(groupedByStudent).map(([key, value]) => ({
			student: key,
			activityList: value,
		}));
	} else if (groupBy === "week") {
		const groupedByWeek = {};

		data.forEach((day) => {
			const startOfWeek = moment(day.date).startOf("isoWeek");
			const weekKey = startOfWeek.format("GGGG-[W]WW");
			day.activityList.forEach((activity) => {
				if (!groupedByWeek[weekKey]) {
					groupedByWeek[weekKey] = [];
				}
				groupedByWeek[weekKey].push({
					...activity,
					date: day.date,
					weekNumber: day.weekNumber,
					periodNumber: day.periodNumber,
					periodTitle: day.periodTitle,
				});
			});
		});

		return Object.entries(groupedByWeek).map(([key, value]) => ({
			key: key,
			activityList: value,
			periodNumber: value[0].periodNumber,
			weekNumber: value[0].weekNumber,
			periodTitle: value[0].periodTitle,
			weekTitle: value[0].weekTitle,
		}));
	}
}
