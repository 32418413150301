import { BrowserRouter } from "react-router-dom";
import "./App.css";

import { Context } from "./Context/Contexts";
import { CustomerSupportModal } from "./Pages/CustomerSupport/CustomerSupport";

import Navbar_Home from "./Components/Navbar/Navbar_Home";
import { GetContextAppRoutes } from "./Context/ContextProviderRoutes";
import ContextProviderValues from "./Context/ContextProviderValues";
import { UserProfileModal } from "./Pages/UserProfile/UserProfile";
import WorldMapUsers_Modal from "./Components/WorldMapUsers/WorldMapUsers_Modal";
import DoneComponent from "./Components/DoneComponent/DoneComponent";
import IsLoadingComponent from "./Components/Isloading/IsLoadingComponent";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MembershipNeedUpgrade from "./Components/Membership/Upgrade/MembershipNeedUpgrade";
import ChildrenSelectorPopUp from "./Components/Children/ChildrenSelectorPopUp/ChildrenSelectorPopUp";
import useInactivityLogout from "./Components/AutoLogout/useInactivityLogout";
import useTokenCheck from "./Components/TokenCheck/useTokenCheck";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import theme from "./theme";
import AutomatedPlanning from "./Components/Planner/AutomatedPlanning/AutomatedPlanning";
import CouponComponent from "./Components/CouponComponent/CouponComponent";
import ThirdPartyCreateUser from "./Components/ThirdPartyAdminModule/ThirdPartyCreateUser/ThirdPartyCreateUser";
import DoneComponentTransactionRetries from "./Components/DoneComponentTransactionRetries/DoneComponentTransactionRetries";

function App() {
	return (
		<Context.Provider value={ContextProviderValues()}>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEAUTH_CLIENTID}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<BrowserRouter>
						<Navbar_Home />
						<MembershipNeedUpgrade />
						{GetContextAppRoutes()}
						<InactivityLogoutHandler />
						<TokenCheck />
					</BrowserRouter>

					<CustomerSupportModal />
					<UserProfileModal />
					<ThirdPartyCreateUser />
					<ChildrenSelectorPopUp />
					<AutomatedPlanning />
					<CouponComponent />
					<WorldMapUsers_Modal />
					<DoneComponent />
					<DoneComponentTransactionRetries />
					<IsLoadingComponent />
				</ThemeProvider>
			</GoogleOAuthProvider>
		</Context.Provider>
	);
}
function InactivityLogoutHandler() {
	useInactivityLogout();
	return null;
}

function TokenCheck() {
	useTokenCheck();
	return null;
}

export default App;
